export default {
  data() {
    return {
      isToast: false,
    };
  },
  methods: {
    openToast(text) {
      // this.$copyText(text);
      if (!this.isToast) {
        this.isToast = true;
        setTimeout(() => {
          this.isToast = false;
        }, 1000);
      }
    },
    closeToast() {
      this.isToast = false;
    },
  },
};

<template>
  <div class="bar-item">
    <div class="bar-item-content">
      <div class="bar-wrap" :style="barWrapStyle">
        <div class="bar-small-top" :style="barTopStyle"></div>
        <div class="bar-big" :style="barMiddleStyle"></div>
        <div
          class="bar-small-top bar-small-bottom"
          :style="barBottomStyle"
        ></div>
        <div class="mask" :style="maskStyle"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    barItem: {
      type: Object,
      default: Object,
    },
  },
  computed: {
    ...mapGetters(['isMobileSize']),
    barWrapStyle() {
      // let height = 0
      // if (this.isMobileSize) {
      //   height = 50
      // }
      // return 'margin-top:' + (this.barItem.mtTop + height) + 'px';
      return 'margin-top:' + this.barItem.mtTop + 'px';
    },
    barTopStyle() {
      return (
        'background: ' +
        this.barItem.background +
        ';' +
        'height: ' +
        this.barItem.topHeight +
        'px'
      );
    },
    barMiddleStyle() {
      return (
        'background: ' +
        this.barItem.background +
        ';' +
        'height: ' +
        this.barItem.middleHeight +
        'px'
      );
    },
    barBottomStyle() {
      return (
        'background: ' +
        this.barItem.background +
        ';' +
        'height: ' +
        this.barItem.bottomHeight +
        'px'
      );
    },
    // 裁剪遮罩
    maskStyle() {
      const topHeight = this.barItem.topHeight;
      const middleHeight = this.barItem.middleHeight;
      const bottomHeight = this.barItem.bottomHeight;
      const point1 = '35% 5';
      const point2 = '65% 5';
      const point3 = '65% ' + topHeight + 5 + 'px';
      const point4 = '105% ' + topHeight + 5 + 'px';
      const point5 = '105% ' + (topHeight + middleHeight + 5) + 'px';
      const point6 = '65% ' + (topHeight + middleHeight + 5) + 'px';
      const point7 = '65% 105%';
      const point8 = '35% 105%';
      const point9 = '35% ' + (topHeight + middleHeight + 5) + 'px';
      const point10 = '-5% ' + (topHeight + middleHeight - 5) + 'px';
      const point11 = '-5% ' + topHeight - 5 + 'px';
      const point12 = '35% ' + topHeight + 5 + 'px';
      return (
        'height: ' + this.barItem.maskHeight + 'px' +
        ';' +
        `clip-path: polygon(evenodd, ${point1}, ${point2}, ${point3}, ${point4}, ${point5}, ${point6}, ${point7}, ${point8}, ${point9}, ${point10}, ${point11}, ${point12})`
      );
    },
  },
};
</script>

<style lang="less" scoped>
.bar-item {
  width: inherit;
  position: relative;
  @keyframes barAni {
    0% {
      height: 100%;
    }
    100% {
      height: 0;
    }
  }
  .bar-small-top {
    width: 20%;
  }
  .bar-big {
    width: 100%;
  }
  .bar-item-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0);
    .bar-wrap {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      // &::before {
      //   content: '';
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   width: 100%;
      //   height: 100%;
      //   // background-color: rgba(0, 0, 0, 0.5);
      //   z-index: 1;
      // }
      .mask {
        position: absolute;
        width: 100%;
        background: var(--kline-bg);
        top: 0;
        // background: var(--bg);
        // animation: barAni 2s ease-in 0s normal forwards paused;
        &.ani {
          animation: barAni 0.01s ease-in 0s normal forwards;
        }
      }
    }
  }
}
</style>

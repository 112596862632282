import { render, staticRenderFns } from "./BarItem.vue?vue&type=template&id=efcc7d24&scoped=true&"
import script from "./BarItem.vue?vue&type=script&lang=js&"
export * from "./BarItem.vue?vue&type=script&lang=js&"
import style0 from "./BarItem.vue?vue&type=style&index=0&id=efcc7d24&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efcc7d24",
  null
  
)

export default component.exports
<template>
  <div class="vue-bottom-dialog" @click="close">
    <div class="dialog-container" :class="{ 'dialog-ani': isAni }" ref="dialogContainer">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isAni: false
    }
  },
  computed: {
    ...mapGetters(['isAppSettingDialog']),
  },
  watch: {
    isAppSettingDialog: {
      handler(n) {
        if (n) {
          setTimeout(() => {
            this.isAni = true
          }, 0);
        } else {
          this.isAni = false
        }
      },
      immediate: true
    }
  },
  mounted() {
    // 监听，除了点击自己，点击其他地方将自身隐藏
    // document.addEventListener('click', this.onOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onOutside);
  },
  methods: {
    onOutside(e) {
      const dialogContainer = this.$refs.dialogContainer;
      if (dialogContainer && !dialogContainer.contains(e.target)) {
        // this.$emit('hideAppSettingDialog')
      }
    },
    close(e) {
      const dialogContainer = this.$refs.dialogContainer;
      if (dialogContainer && !dialogContainer.contains(e.target)) {
        this.$store.commit('setAppSettingDialog', false)
      }
    },
  },
};
</script>

<style lang="less" scoped>
.vue-bottom-dialog {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  // width: 100vw;
  // height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  .dialog-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: var(--wrap-bg);
    border-radius: 24px 24px 0px 0px;
    opacity: 0;
    transform: scaleY(0);
    transform-origin: 50% bottom;
    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s, transform 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    &.dialog-ani {
      opacity: 1;
      transform: scaleY(1);
    }
  }
}
</style>

// const red = #ff0000
const red = '#e55036';
const green = '#03c288';
export const BAR_LIST = [
  {
    order: 0,
    mtTop: 520,
    background: green,
    maskHeight: 20,
    topHeight: 0,
    middleHeight: 20,
    bottomHeight: 0,
  },
  {
    order: 1,
    mtTop: 505,
    background: red,
    maskHeight: 75,
    topHeight: 15,
    middleHeight: 50,
    bottomHeight: 10,
  },
  {
    order: 2,
    mtTop: 540,
    background: green,
    maskHeight: 50,
    topHeight: 10,
    middleHeight: 20,
    bottomHeight: 10,
  },
  {
    order: 3,
    mtTop: 520,
    background: green,
    maskHeight: 50,
    topHeight: 10,
    middleHeight: 15,
    bottomHeight: 10,
  },
  {
    order: 4,
    mtTop: 500,
    background: green,
    maskHeight: 35,
    topHeight: 10,
    middleHeight: 15,
    bottomHeight: 10,
  },
  {
    order: 5,
    mtTop: 485,
    background: red,
    maskHeight: 60,
    topHeight: 25,
    middleHeight: 10,
    bottomHeight: 25,
  },
  {
    order: 6,
    mtTop: 515,
    background: green,
    maskHeight: 5,
    topHeight: 0,
    middleHeight: 5,
    bottomHeight: 0,
  },
  {
    order: 7,
    mtTop: 505,
    background: green,
    maskHeight: 15,
    topHeight: 0,
    middleHeight: 15,
    bottomHeight: 0,
  },
  {
    order: 8,
    mtTop: 475,
    background: red,
    maskHeight: 50,
    topHeight: 20,
    middleHeight: 30,
    bottomHeight: 0,
  },
  {
    order: 9,
    mtTop: 500,
    background: red,
    maskHeight: 35,
    topHeight: 20,
    middleHeight: 10,
    bottomHeight: 5,
  },
  {
    order: 10,
    mtTop: 490,
    background: green,
    maskHeight: 40,
    topHeight: 0,
    middleHeight: 40,
    bottomHeight: 0,
  },
  {
    order: 11,
    mtTop: 450,
    background: green,
    maskHeight: 40,
    topHeight: 0,
    middleHeight: 40,
    bottomHeight: 0,
  },
  {
    order: 12,
    mtTop: 420,
    background: green,
    maskHeight: 30,
    topHeight: 0,
    middleHeight: 30,
    bottomHeight: 0,
  },
  {
    order: 13,
    mtTop: 380,
    background: red,
    maskHeight: 80,
    topHeight: 20,
    middleHeight: 60,
    bottomHeight: 0,
  },
  {
    order: 14,
    mtTop: 425,
    background: red,
    maskHeight: 46,
    topHeight: 30,
    middleHeight: 8,
    bottomHeight: 8,
  },
  {
    order: 15,
    mtTop: 435,
    background: green,
    maskHeight: 63,
    topHeight: 20,
    middleHeight: 8,
    bottomHeight: 35,
  },
  {
    order: 16,
    mtTop: 430,
    background: red,
    maskHeight: 63,
    topHeight: 25,
    middleHeight: 8,
    bottomHeight: 30,
  },
  {
    order: 17,
    mtTop: 392,
    background: green,
    maskHeight: 80,
    topHeight: 0,
    middleHeight: 70,
    bottomHeight: 10,
  },
  {
    order: 18,
    mtTop: 377,
    background: red,
    maskHeight: 50,
    topHeight: 15,
    middleHeight: 20,
    bottomHeight: 15,
  },
  {
    order: 19,
    mtTop: 332,
    background: green,
    maskHeight: 80,
    topHeight: 0,
    middleHeight: 80,
    bottomHeight: 0,
  },
  {
    order: 20,
    mtTop: 260,
    background: green,
    maskHeight: 76,
    topHeight: 3,
    middleHeight: 70,
    bottomHeight: 0,
  },
  {
    order: 21,
    mtTop: 203,
    background: green,
    maskHeight: 60,
    topHeight: 0,
    middleHeight: 60,
    bottomHeight: 0,
  },
  {
    order: 22,
    mtTop: 193,
    background: red,
    maskHeight: 90,
    topHeight: 10,
    middleHeight: 20,
    bottomHeight: 60,
  },
  {
    order: 23,
    mtTop: 213,
    background: red,
    maskHeight: 50,
    topHeight: 0,
    middleHeight: 20,
    bottomHeight: 30,
  },
  {
    order: 24,
    mtTop: 215,
    background: red,
    maskHeight: 55,
    topHeight: 10,
    middleHeight: 35,
    bottomHeight: 10,
  },
  {
    order: 25,
    mtTop: 200,
    background: green,
    maskHeight: 60,
    topHeight: 0,
    middleHeight: 60,
    bottomHeight: 0,
  },
  {
    order: 26,
    mtTop: 150,
    background: green,
    maskHeight: 50,
    topHeight: 0,
    middleHeight: 50,
    bottomHeight: 0,
  },
  {
    order: 27,
    mtTop: 90,
    background: red,
    maskHeight: 115,
    topHeight: 60,
    middleHeight: 50,
    bottomHeight: 5,
  },
  {
    order: 28,
    mtTop: 195,
    background: red,
    maskHeight: 110,
    topHeight: 5,
    middleHeight: 100,
    bottomHeight: 5,
  },
  {
    order: 29,
    mtTop: 290,
    background: red,
    maskHeight: 50,
    topHeight: 10,
    middleHeight: 40,
    bottomHeight: 0,
  },
  {
    order: 30,
    mtTop: 310,
    background: red,
    maskHeight: 43,
    topHeight: 30,
    middleHeight: 10,
    bottomHeight: 3,
  },
  {
    order: 31,
    mtTop: 310,
    background: green,
    maskHeight: 50,
    topHeight: 0,
    middleHeight: 50,
    bottomHeight: 0,
  },
  {
    order: 32,
    mtTop: 280,
    background: red,
    maskHeight: 45,
    topHeight: 10,
    middleHeight: 35,
    bottomHeight: 0,
  },
  {
    order: 33,
    mtTop: 290,
    background: green,
    maskHeight: 35,
    topHeight: 0,
    middleHeight: 35,
    bottomHeight: 0,
  },
  {
    order: 34,
    mtTop: 235,
    background: green,
    maskHeight: 55,
    topHeight: 20,
    middleHeight: 35,
    bottomHeight: 0,
  },
  {
    order: 35,
    mtTop: 235,
    background: red,
    maskHeight: 45,
    topHeight: 20,
    middleHeight: 10,
    bottomHeight: 15,
  },
  {
    order: 36,
    mtTop: 230,
    background: green,
    maskHeight: 40,
    topHeight: 15,
    middleHeight: 20,
    bottomHeight: 5,
  },
  {
    order: 37,
    mtTop: 245,
    background: green,
    maskHeight: 26,
    topHeight: 0,
    middleHeight: 6,
    bottomHeight: 20,
  },
  {
    order: 38,
    mtTop: 235,
    background: red,
    maskHeight: 60,
    topHeight: 10,
    middleHeight: 50,
    bottomHeight: 0,
  },
  {
    order: 39,
    mtTop: 295,
    background: red,
    maskHeight: 150,
    topHeight: 5,
    middleHeight: 120,
    bottomHeight: 25,
  },
  {
    order: 40,
    mtTop: 414,
    background: red,
    maskHeight: 90,
    topHeight: 5,
    middleHeight: 60,
    bottomHeight: 25,
  },
  {
    order: 41,
    mtTop: 454,
    background: green,
    maskHeight: 26,
    topHeight: 5,
    middleHeight: 20,
    bottomHeight: 1,
  },
  {
    order: 42,
    mtTop: 445,
    background: green,
    maskHeight: 18,
    topHeight: 5,
    middleHeight: 10,
    bottomHeight: 3,
  },
  {
    order: 43,
    mtTop: 423,
    background: green,
    maskHeight: 32,
    topHeight: 5,
    middleHeight: 22,
    bottomHeight: 5,
  },
  {
    order: 44,
    mtTop: 410,
    background: green,
    maskHeight: 25,
    topHeight: 10,
    middleHeight: 8,
    bottomHeight: 7,
  },
  {
    order: 45,
    mtTop: 394,
    background: green,
    maskHeight: 27,
    topHeight: 7,
    middleHeight: 20,
    bottomHeight: 0,
  },
  {
    order: 46,
    mtTop: 390,
    background: red,
    maskHeight: 33,
    topHeight: 3,
    middleHeight: 10,
    bottomHeight: 20,
  },
  {
    order: 47,
    mtTop: 386,
    background: green,
    maskHeight: 17,
    topHeight: 3,
    middleHeight: 14,
    bottomHeight: 0,
  },
  {
    order: 48,
    mtTop: 389,
    background: red,
    maskHeight: 15,
    topHeight: 0,
    middleHeight: 5,
    bottomHeight: 10,
  },
  {
    order: 49,
    mtTop: 346,
    background: green,
    maskHeight: 48,
    topHeight: 8,
    middleHeight: 40,
    bottomHeight: 0,
  },
  {
    order: 50,
    mtTop: 305,
    background: green,
    maskHeight: 70,
    topHeight: 20,
    middleHeight: 30,
    bottomHeight: 20,
  },
  {
    order: 51,
    mtTop: 266,
    background: green,
    maskHeight: 62,
    topHeight: 10,
    middleHeight: 50,
    bottomHeight: 2,
  },
  {
    order: 52,
    mtTop: 202,
    background: green,
    maskHeight: 112,
    topHeight: 35,
    middleHeight: 40,
    bottomHeight: 37,
  },
  {
    order: 53,
    mtTop: 222,
    background: red,
    maskHeight: 70,
    topHeight: 15,
    middleHeight: 20,
    bottomHeight: 35,
  },
  {
    order: 54,
    mtTop: 237,
    background: red,
    maskHeight: 75,
    topHeight: 15,
    middleHeight: 30,
    bottomHeight: 30,
  },
  {
    order: 55,
    mtTop: 232,
    background: green,
    maskHeight: 70,
    topHeight: 35,
    middleHeight: 15,
    bottomHeight: 20,
  },
  {
    order: 56,
    mtTop: 200,
    background: green,
    maskHeight: 78,
    topHeight: 18,
    middleHeight: 50,
    bottomHeight: 10,
  },
  {
    order: 57,
    mtTop: 130,
    background: green,
    maskHeight: 100,
    topHeight: 10,
    middleHeight: 80,
    bottomHeight: 10,
  },
  {
    order: 58,
    mtTop: 75,
    background: green,
    maskHeight: 84,
    topHeight: 7,
    middleHeight: 60,
    bottomHeight: 17,
  },
  {
    order: 59,
    mtTop: 82,
    background: red,
    maskHeight: 105,
    topHeight: 0,
    middleHeight: 85,
    bottomHeight: 20,
  },
  {
    order: 60,
    mtTop: 115,
    background: green,
    maskHeight: 52,
    topHeight: 10,
    middleHeight: 42,
    bottomHeight: 0,
  },
  {
    order: 61,
    mtTop: 47,
    background: green,
    maskHeight: 95,
    topHeight: 20,
    middleHeight: 60,
    bottomHeight: 15,
  },
  {
    order: 62,
    mtTop: 52,
    background: red,
    maskHeight: 60,
    topHeight: 15,
    middleHeight: 15,
    bottomHeight: 30,
  },
  {
    order: 63,
    mtTop: 71,
    background: red,
    maskHeight: 65,
    topHeight: 10,
    middleHeight: 15,
    bottomHeight: 40,
  },
  {
    order: 64,
    mtTop: 52,
    background: green,
    maskHeight: 53,
    topHeight: 20,
    middleHeight: 25,
    bottomHeight: 8,
  },
  {
    order: 65,
    mtTop: 40,
    background: green,
    maskHeight: 54,
    topHeight: 4,
    middleHeight: 30,
    bottomHeight: 20,
  },
  {
    order: 66,
    mtTop: 10,
    background: red,
    maskHeight: 118,
    topHeight: 35,
    middleHeight: 45,
    bottomHeight: 38,
  },
  {
    order: 67,
    mtTop: 73,
    background: red,
    maskHeight: 90,
    topHeight: 15,
    middleHeight: 60,
    bottomHeight: 15,
  },
  {
    order: 68,
    mtTop: 69,
    background: green,
    maskHeight: 83,
    topHeight: 10,
    middleHeight: 70,
    bottomHeight: 3,
  },
  {
    order: 69,
    mtTop: 55,
    background: green,
    maskHeight: 55,
    topHeight: 10,
    middleHeight: 15,
    bottomHeight: 30,
  },
  {
    order: 70,
    mtTop: 74,
    background: red,
    maskHeight: 110,
    topHeight: 5,
    middleHeight: 90,
    bottomHeight: 15,
  },
  {
    order: 72,
    mtTop: 165,
    background: red,
    maskHeight: 128,
    topHeight: 3,
    middleHeight: 90,
    bottomHeight: 35,
  },
  {
    order: 73,
    mtTop: 208,
    background: green,
    maskHeight: 58,
    topHeight: 40,
    middleHeight: 10,
    bottomHeight: 8,
  },
  {
    order: 74,
    mtTop: 219,
    background: green,
    maskHeight: 41,
    topHeight: 25,
    middleHeight: 6,
    bottomHeight: 10,
  },
  {
    order: 75,
    mtTop: 215,
    background: green,
    maskHeight: 70,
    topHeight: 5,
    middleHeight: 25,
    bottomHeight: 40,
  },
  {
    order: 76,
    mtTop: 210,
    background: red,
    maskHeight: 63,
    topHeight: 10,
    middleHeight: 35,
    bottomHeight: 18,
  },
  {
    order: 77,
    mtTop: 252,
    background: red,
    maskHeight: 52,
    topHeight: 2,
    middleHeight: 10,
    bottomHeight: 40,
  },
  {
    order: 78,
    mtTop: 242,
    background: green,
    maskHeight: 37,
    topHeight: 10,
    middleHeight: 12,
    bottomHeight: 15,
  },
  {
    order: 79,
    mtTop: 252,
    background: red,
    maskHeight: 35,
    topHeight: 0,
    middleHeight: 15,
    bottomHeight: 20,
  },
  {
    order: 80,
    mtTop: 262,
    background: red,
    maskHeight: 29,
    topHeight: 4,
    middleHeight: 20,
    bottomHeight: 5,
  },
  {
    order: 81,
    mtTop: 250,
    background: green,
    maskHeight: 48,
    topHeight: 0,
    middleHeight: 40,
    bottomHeight: 18,
  },
  {
    order: 82,
    mtTop: 192,
    background: green,
    maskHeight: 60,
    topHeight: 20,
    middleHeight: 40,
    bottomHeight: 0,
  },
  {
    order: 83,
    mtTop: 160,
    background: green,
    maskHeight: 83,
    topHeight: 15,
    middleHeight: 40,
    bottomHeight: 28,
  },
  {
    order: 84,
    mtTop: 140,
    background: green,
    maskHeight: 50,
    topHeight: 10,
    middleHeight: 30,
    bottomHeight: 10,
  },
  {
    order: 85,
    mtTop: 130,
    background: green,
    maskHeight: 55,
    topHeight: 5,
    middleHeight: 20,
    bottomHeight: 30,
  },
  {
    order: 86,
    mtTop: 125,
    background: red,
    maskHeight: 36,
    topHeight: 10,
    middleHeight: 6,
    bottomHeight: 20,
  },
  {
    order: 87,
    mtTop: 135,
    background: green,
    maskHeight: 50,
    topHeight: 0,
    middleHeight: 30,
    bottomHeight: 20,
  },
  {
    order: 88,
    mtTop: 125,
    background: red,
    maskHeight: 80,
    topHeight: 10,
    middleHeight: 50,
    bottomHeight: 20,
  },
  {
    order: 89,
    mtTop: 175,
    background: green,
    maskHeight: 36,
    topHeight: 8,
    middleHeight: 20,
    bottomHeight: 8,
  },
  {
    order: 90,
    mtTop: 184,
    background: red,
    maskHeight: 60,
    topHeight: 0,
    middleHeight: 30,
    bottomHeight: 30,
  },
  {
    order: 91,
    mtTop: 159,
    background: green,
    maskHeight: 75,
    topHeight: 15,
    middleHeight: 40,
    bottomHeight: 20,
  },
  {
    order: 92,
    mtTop: 110,
    background: green,
    maskHeight: 80,
    topHeight: 20,
    middleHeight: 45,
    bottomHeight: 15,
  },
  {
    order: 93,
    mtTop: 70,
    background: green,
    maskHeight: 75,
    topHeight: 15,
    middleHeight: 45,
    bottomHeight: 15,
  },
  {
    order: 94,
    mtTop: 70,
    background: red,
    maskHeight: 28,
    topHeight: 15,
    middleHeight: 8,
    bottomHeight: 5,
  },
  {
    order: 95,
    mtTop: 65,
    background: green,
    maskHeight: 45,
    topHeight: 20,
    middleHeight: 5,
    bottomHeight: 20,
  },
  {
    order: 96,
    mtTop: 65,
    background: green,
    maskHeight: 40,
    topHeight: 10,
    middleHeight: 15,
    bottomHeight: 15,
  },
  {
    order: 97,
    mtTop: 32,
    background: green,
    maskHeight: 53,
    topHeight: 25,
    middleHeight: 20,
    bottomHeight: 8,
  },
  {
    order: 98,
    mtTop: 52,
    background: red,
    maskHeight: 80,
    topHeight: 5,
    middleHeight: 60,
    bottomHeight: 15,
  },
  {
    order: 99,
    mtTop: 105,
    background: red,
    maskHeight: 58,
    topHeight: 10,
    middleHeight: 13,
    bottomHeight: 35,
  },
  {
    order: 100,
    mtTop: 107,
    background: red,
    maskHeight: 150,
    topHeight: 20,
    middleHeight: 60,
    bottomHeight: 70,
  },
  {
    order: 101,
    mtTop: 165,
    background: green,
    maskHeight: 55,
    topHeight: 20,
    middleHeight: 10,
    bottomHeight: 25,
  },
  {
    order: 102,
    mtTop: 185,
    background: red,
    maskHeight: 50,
    topHeight: 10,
    middleHeight: 30,
    bottomHeight: 10,
  },
  {
    order: 103,
    mtTop: 175,
    background: green,
    maskHeight: 60,
    topHeight: 15,
    middleHeight: 35,
    bottomHeight: 10,
  },
  {
    order: 104,
    mtTop: 178,
    background: red,
    maskHeight: 57,
    topHeight: 12,
    middleHeight: 30,
    bottomHeight: 15,
  },
  {
    order: 105,
    mtTop: 219,
    background: red,
    maskHeight: 52,
    topHeight: 0,
    middleHeight: 40,
    bottomHeight: 12,
  },
  {
    order: 106,
    mtTop: 239,
    background: green,
    maskHeight: 40,
    topHeight: 5,
    middleHeight: 15,
    bottomHeight: 20,
  },
  {
    order: 107,
    mtTop: 241,
    background: red,
    maskHeight: 86,
    topHeight: 3,
    middleHeight: 70,
    bottomHeight: 13,
  },
  {
    order: 108,
    mtTop: 294,
    background: green,
    maskHeight: 40,
    topHeight: 5,
    middleHeight: 15,
    bottomHeight: 20,
  },
  {
    order: 109,
    mtTop: 265,
    background: green,
    maskHeight: 50,
    topHeight: 3,
    middleHeight: 35,
    bottomHeight: 12,
  },
  {
    order: 110,
    mtTop: 243,
    background: red,
    maskHeight: 32,
    topHeight: 25,
    middleHeight: 5,
    bottomHeight: 2,
  },
  {
    order: 111,
    mtTop: 250,
    background: red,
    maskHeight: 53,
    topHeight: 25,
    middleHeight: 25,
    bottomHeight: 3,
  },
  {
    order: 112,
    mtTop: 285,
    background: red,
    maskHeight: 45,
    topHeight: 15,
    middleHeight: 5,
    bottomHeight: 25,
  },
  {
    order: 113,
    mtTop: 262,
    background: green,
    maskHeight: 46,
    topHeight: 35,
    middleHeight: 8,
    bottomHeight: 3,
  },
  {
    order: 114,
    mtTop: 272,
    background: red,
    maskHeight: 38,
    topHeight: 25,
    middleHeight: 5,
    bottomHeight: 8,
  },
  {
    order: 115,
    mtTop: 278,
    background: green,
    maskHeight: 25,
    topHeight: 0,
    middleHeight: 25,
    bottomHeight: 0,
  },
  {
    order: 116,
    mtTop: 245,
    background: green,
    maskHeight: 42,
    topHeight: 0,
    middleHeight: 35,
    bottomHeight: 7,
  },
  {
    order: 117,
    mtTop: 237,
    background: red,
    maskHeight: 28,
    topHeight: 8,
    middleHeight: 5,
    bottomHeight: 15,
  },
  {
    order: 118,
    mtTop: 240,
    background: red,
    maskHeight: 48,
    topHeight: 10,
    middleHeight: 30,
    bottomHeight: 8,
  },
  {
    order: 119,
    mtTop: 275,
    background: red,
    maskHeight: 29,
    topHeight: 5,
    middleHeight: 20,
    bottomHeight: 4,
  },
  {
    order: 120,
    mtTop: 275,
    background: red,
    maskHeight: 35,
    topHeight: 15,
    middleHeight: 4,
    bottomHeight: 6,
  },
  {
    order: 121,
    mtTop: 288,
    background: red,
    maskHeight: 29,
    topHeight: 16,
    middleHeight: 9,
    bottomHeight: 4,
  },
  {
    order: 122,
    mtTop: 297,
    background: red,
    maskHeight: 49,
    topHeight: 15,
    middleHeight: 30,
    bottomHeight: 4,
  },
  {
    order: 123,
    mtTop: 262,
    background: green,
    maskHeight: 160,
    topHeight: 10,
    middleHeight: 70,
    bottomHeight: 80,
  },
  {
    order: 124,
    mtTop: 233,
    background: green,
    maskHeight: 40,
    topHeight: 10,
    middleHeight: 30,
    bottomHeight: 0,
  },
  {
    order: 125,
    mtTop: 205,
    background: green,
    maskHeight: 50,
    topHeight: 0,
    middleHeight: 40,
    bottomHeight: 10,
  },
  {
    order: 126,
    mtTop: 180,
    background: green,
    maskHeight: 48,
    topHeight: 15,
    middleHeight: 20,
    bottomHeight: 13,
  },
  {
    order: 127,
    mtTop: 192,
    background: red,
    maskHeight: 18,
    topHeight: 3,
    middleHeight: 5,
    bottomHeight: 10,
  },
  {
    order: 128,
    mtTop: 150,
    background: green,
    maskHeight: 50,
    topHeight: 0,
    middleHeight: 50,
    bottomHeight: 0,
  },
  {
    order: 129,
    mtTop: 143,
    background: green,
    maskHeight: 23,
    topHeight: 3,
    middleHeight: 5,
    bottomHeight: 15,
  },
  {
    order: 130,
    mtTop: 131,
    background: red,
    maskHeight: 50,
    topHeight: 15,
    middleHeight: 15,
    bottomHeight: 20,
  },
  {
    order: 131,
    mtTop: 156,
    background: red,
    maskHeight: 44,
    topHeight: 4,
    middleHeight: 5,
    bottomHeight: 35,
  },
  {
    order: 132,
    mtTop: 125,
    background: green,
    maskHeight: 52,
    topHeight: 15,
    middleHeight: 25,
    bottomHeight: 12,
  },
  {
    order: 133,
    mtTop: 82,
    background: green,
    maskHeight: 60,
    topHeight: 0,
    middleHeight: 60,
    bottomHeight: 0,
  },
  {
    order: 134,
    mtTop: 55,
    background: green,
    maskHeight: 26,
    topHeight: 20,
    middleHeight: 6,
    bottomHeight: 0,
  },
  {
    order: 135,
    mtTop: 49,
    background: green,
    maskHeight: 32,
    topHeight: 7,
    middleHeight: 20,
    bottomHeight: 5,
  },
  {
    order: 136,
    mtTop: 31,
    background: red,
    maskHeight: 100,
    topHeight: 25,
    middleHeight: 40,
    bottomHeight: 35,
  },
  {
    order: 137,
    mtTop: 80,
    background: green,
    maskHeight: 37,
    topHeight: 5,
    middleHeight: 10,
    bottomHeight: 22,
  },
  {
    order: 138,
    mtTop: 37,
    background: green,
    maskHeight: 50,
    topHeight: 0,
    middleHeight: 50,
    bottomHeight: 0,
  },
];

<template>
  <div class="tp-modal">
    <div class="tp-modal-content">
      <div class="content">
        <img :src="modalImg" />
        <div class="desc1">
          <img src="../../assets/dialog/danger.png" alt="" />
          <span>{{ $t('home.modal_contact_us.desc1') }}</span>
        </div>
        <div class="list">
          <div
            class="item"
            v-for="(item, index) in cList"
            :key="index"
            :class="{ selected: item.select }"
          >
            <div class="item-left" @click="onSelect(item)">
              <img
                v-if="item.select"
                src="../../assets/dialog/select.png"
                alt=""
              />
              <div class="no-select" v-else></div>
            </div>
            <div class="item-right">{{ item.text }}</div>
          </div>
        </div>
        <div
          @click="openTG"
          class="button"
          :class="{ active: isActive }"
        >
          {{ $t('home.modal_contact_us.desc3') }}
        </div>
      </div>
      <img
        class="close"
        :src="closeModalImg"
        @click="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
import darkModeImg from '../../mixins/darkMode';
export default {
  props: {
    url: {
      type: String,
    },
  },
  data() {
    return {
      list: [
        {
          select: false,
          text: this.$t('home.modal_contact_us.tg_text_1'),
        },
        {
          select: false,
          text: this.$t('home.modal_contact_us.tg_text_2'),
        },
        {
          select: false,
          text: this.$t('home.modal_contact_us.tg_text_3'),
        },
      ],
    };
  },
  mixins: [darkModeImg],
  computed: {
    modalImg() {
      return require(`../../assets/dialog/modal-img${this.darkModeImg}.png`);
    },
    closeModalImg() {
      return require(`../../assets/dialog/close-modal${this.darkModeImg}.png`);
    },
    cList() {
      return this.list;
    },
    isActive() {
      return this.cList.every((item) => item.select);
    },
  },
  methods: {
    onSelect(item) {
      item.select = !item.select;
    },
    openTG() {
      if (this.isActive) {
        this.$emit('close')
        window.open(this.url, 'blank')
      }
    }
  },
};
</script>

<style lang="less" scoped>
.tp-modal {
  position: fixed;
  z-index: 9998;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .tp-modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: var(--tg-modal-bg);
    width: 378px;
    border: 1px solid var(--tg-modal-border);
    border-radius: 20px;
    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.20); 
    .content {
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px 24px 32px;
      img {
        width: 160px;
        height: 98px;
      }
      .desc1 {
        margin-top: 8px;
        background: rgba(229, 80, 54, 0.07);
        border-radius: 12px;
        padding: 12px;
        display: flex;
        img {
          width: 18px;
          height: 18px;
        }
        span {
          margin-left: 8px;
          font-size: 13px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #e55036;
          line-height: 18px;
        }
      }
      .list {
        margin-top: 16px;
        display: grid;
        gap: 12px 0;
        .item {
          border: 1px solid var(--tg-modal-item-border);
          border-radius: 12px;
          padding: 12px;
          display: flex;
          &.selected {
            background: var(--tg-modal-item-active-bg);
            border: 1px solid #2980fe;
            .item-right {
              color: #2980fe;
            }
          }
          .item-left {
            display: flex;
            cursor: pointer;
            img {
              margin-top: 2px;
              width: 14px;
              height: 14px;
              border: 1px solid transparent;
            }
            .no-select {
              margin-top: 2px;
              width: 14px;
              height: 14px;
              opacity: 0.7;
              background: var(--tg-modal-no-select-bg);
              border: 1px solid var(--tg-modal-no-select-border);
              border-radius: 4px;
            }
          }
          .item-right {
            margin-left: 8px;
            font-size: 12px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: var(--tg-modal-item-color);
            line-height: 17px;
          }
        }
      }
      .button {
        width: 100%;
        margin-top: 16px;
        height: 48px;
        line-height: 48px;
        background: rgba(41, 128, 254, 0.1);
        border-radius: 12px;
        font-size: 13px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: center;
        color: var(--tg-modal-button-color);
        cursor: pointer;
        &.active {
          background: rgba(41, 128, 254, 1);
          color: #fff;
        }
      }
    }
    .close {
      position: absolute;
      top: 20px;
      right: 16px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 767px) {
  .tp-modal {
    .tp-modal-content {
      width: 88%;
      border-radius: 16px;
      .content {
        border-radius: 16px;
        .desc1 {
          margin-top: 16px;
        }
        .list {
          margin-top: 8px;
          gap: 8px 0;
        }
        .button {
          margin-top: 16px;
          font-size: 13px;
        }
      }
      .close {
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isShow)?_c('div',{staticClass:"fixed-container"},[_c('div',{staticClass:"outer"},[_c('div',{staticClass:"app-toast",class:{
       'info': _vm.type=== 'info',
       'success': _vm.type=== 'success',
       'warning': _vm.type=== 'warning',
       'danger': _vm.type=== 'danger',
       'pc': this.isPC,
       'sentence': this.isSentence
    }},[_vm._v(_vm._s(_vm.text)+" ")])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from 'vue';
import Vuex from 'vuex';
// import tp from "tp-js-sdk";
// import { setMode } from "../utils";

Vue.use(Vuex);

// let theme = localStorage.getItem('theme');
// let mode = false;
// console.log('store', theme);

// // 如果已经设置过暗黑模式
// const isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)'); // 是深色
// console.log('isDarkTheme', isDarkTheme);
// if (theme) {
//   if (theme === 'auto' && isDarkTheme) {
//     if (isDarkTheme.matches) {
//       // 主题设置为深色。
//       mode = true;
//     } else {
//       // 主题设置为浅色。
//       mode = false;
//     }
//   } else if (theme == 'dark') {
//     mode = true;
//   } else {
//     mode = false;
//   }
//   setMode(mode)
// } else {
//   if (navigator.userAgent.indexOf('TokenPocket') === -1) {
//     if (isDarkTheme.matches) {
//       // 主题设置为深色。
//       mode = true;
//     } else {
//       // 主题设置为浅色。
//       mode = false;
//     }
//     setMode(mode)
//   } else {
//     tp.isDarkMode().then(res => {
//       console.log('tp', res);
//       if (res && res.data) {
//         mode = true
//       } else {
//         mode = false
//       }
//       setMode(mode)
//     })
//   }
// }

// if (mode) {
//   document.documentElement.setAttribute('theme', 'dark');
//   console.log('isDarkMode', mode);
//   // localStorage.setItem('theme', 'dark')
// } else {
//   document.documentElement.removeAttribute('theme');
//   // localStorage.setItem('theme', 'light')
// }

export default new Vuex.Store({
  state: {
    isDarkMode: false,
    isMobileSize: false,
    isAppSettingDialog: false,
  },
  mutations: {
    setDarkMode(state, payload) {
      state.isDarkMode = payload;
    },
    setMobileSize(state, payload) {
      state.isMobileSize = payload;
    },
    setAppSettingDialog(state, payload) {
      state.isAppSettingDialog = payload;
    },
  },
  actions: {},
  getters: {
    isDarkMode(state) {
      return state.isDarkMode;
    },
    isMobileSize(state) {
      return state.isMobileSize;
    },
    isAppSettingDialog(state) {
      return state.isAppSettingDialog;
    },
  },
  modules: {},
});

<template>
  <div class="home-view">
    <Header />
    <HomeContainer />
    <Footer />
  </div>
</template>

<script>
import Header from '../components/Layouts/Header.vue';
import Footer from '../components/Layouts/Footer.vue';
import HomeContainer from '../components/Layouts/HomeContainer.vue';
import { setMode, changeURLArg, createQueryObj } from "../utils";
export default {
  name: 'Home',
  components: { Header, Footer, HomeContainer },
  data() {
    return {};
  },
  // beforeRouteLeave(to, from, next) {
  //   window.localStorage.setItem('theme', 'light');
  //   this.$store.commit('setDarkMode', false);
  //   setMode(false)
  //   next();
  // },
  beforeRouteEnter(to, from, next) {
    const queryObj = createQueryObj()
    const theme = queryObj.theme
    changeURLArg({ theme })
    let mode = false
    if (theme === 'dark') {
      mode = true
    }
    setMode(mode)
    next(vm => {
      vm.$store.commit('setDarkMode', mode);
    });
  }
};
</script>
<style lang="less">
.home-view {
  background: var(--bg);
}
</style>

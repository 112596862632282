import darkMode from './darkMode';
export default {
  mixins: [darkMode],
  computed: {
    swapUrl() {
      if (process.env.VUE_APP_ENV !== 'production') {
        return `https://test-tp1.tokenpocket.pro/dev/?locale=${this.$i18n.locale}&theme=${this.darkModeTheme}#/`;
      } else {
        return `https://swap.transit.finance/?locale=${this.$i18n.locale}&theme=${this.darkModeTheme}#/`;
      }
    },
    swapMarketUrl() {
      if (process.env.VUE_APP_ENV !== 'production') {      
        return `https://test-tp1.tokenpocket.pro/dev/?locale=${this.$i18n.locale}&theme=${this.darkModeTheme}#/market`;
      } else {
        return `https://swap.transit.finance/?locale=${this.$i18n.locale}&theme=${this.darkModeTheme}#/market`;
      }
    },
    transitBuyUrl() {
      return `https://buy.transit.finance/?locale=${this.$i18n.locale}&theme=${this.darkModeTheme}`
    },
    transitExplorerUrl() {
      return `https://explorer.transit.finance/?locale=${this.$i18n.locale}&theme=${this.darkModeTheme}`
    },
  },
};

<template>
  <div
    class="search-token-container"
    :class="{ english: $i18n.locale === 'en' }"
  >
    <div class="search-back-wrap" v-if="isMobileSize && searchTokensVisible">
      <img
        :src="
          require(`../../assets/header/search/search-back${
            isDarkMode ? '-dark' : ''
          }.png`)
        "
        @click="searchTokensVisible = false"
      />
      <input
        class="search mobile-search"
        :class="{ focus: searchTokensVisible }"
        v-if="!isMobileSize || (isMobileSize && searchTokensVisible)"
        :placeholder="$t('header.searchTokens')"
        @focus="showSearchTokens"
        @input="swapMarketInputChange"
        v-model="swapMarketSearchInput"
      />
    </div>

    <input
      class="search"
      :class="{ focus: searchTokensVisible }"
      v-if="!isMobileSize || (isMobileSize && searchTokensVisible)"
      :placeholder="$t('header.searchTokens')"
      @focus="showSearchTokens"
      @input="swapMarketInputChange"
      v-model="swapMarketSearchInput"
    />

    <div
      class="search-result-wrap"
      v-if="searchTokensVisible"
      @click.self="searchTokensVisible = false"
    >
      <div class="search-result-content">
        <div class="tokens" v-if="!swapMarketSearchInput">
          <!-- <div
            class="tokens-title"
            v-if="searchHistoryTokens && searchHistoryTokens.length"
          >
            {{ $t('header.recentSearches') }}
          </div> -->
          <div
            class="token-title-wrap"
            v-if="searchHistoryTokens && searchHistoryTokens.length"
          >
            <div class="left-title">{{ $t('header.recentSearches') }}</div>
            <img :src="deleteImg" @click="onDelete" alt="" />
          </div>
          <div
            class="token"
            v-for="(item, index) in searchHistoryTokens"
            :key="`history-token-${index}`"
            @click="selectSearchedToken(item)"
          >
            <div class="token-logo-wrap">
              <img
                :src="item.icon"
                class="token-logo"
                onerror="javascript:this.src='https://tokenpocket.cdn.bcebos.com/common.png';"
              />
              <img
                class="network-logo"
                :src="
                  require(`../../assets/header/search/${item.network}.png`)
                "
                onerror="javascript:this.src='https://tokenpocket.cdn.bcebos.com/common.png';"
              />
            </div>
            <div class="token-symbol-network-wrap">
              <div class="symbol">{{ item.symbol }}</div>
              <div class="name">{{ item.address.slice(0,5) + '...' + item.address.slice(-5) }}</div>
            </div>
            <div class="token-market-wrap">
              <!-- <div class="price">{{ `$${item.price}` }}</div> -->
              <div class="price">{{ `$${parsePrice(item.price)}` }}</div>
              <div
                :class="[
                  'chg',
                  (item.change || '').startsWith('-') ? 'down' : 'up',
                ]"
              >
                {{ item.change }}
              </div>
            </div>
          </div>
          <div class="tokens-title popular">{{ $t('header.popularTokens') }}</div>
          <div
            class="token"
            v-for="(item, index) in hotTokenList"
            :key="`token-${index}`"
            @click="selectSearchedToken(item)"
          >
            <div class="token-logo-wrap">
              <img v-if="index <= 2" class="token-logo-order" src="../../assets/header/search/rexiao.png" alt=""/>
              <span v-else class="token-order">{{ index + 1 }}</span>
              <img
                :src="item.icon"
                class="token-logo"
                onerror="javascript:this.src='https://tokenpocket.cdn.bcebos.com/common.png';"
              />
              <img
                v-if="item.networkIcon"
                class="network-logo"
                :src="item.networkIcon"
                onerror="javascript:this.src='https://tokenpocket.cdn.bcebos.com/common.png';"
              />
            </div>
            <div class="token-symbol-network-wrap">
              <div class="symbol">{{ item.symbol }}</div>
              <div class="name">{{ item.address.slice(0,5) + '...' + item.address.slice(-5) }}</div>
            </div>
            <div class="token-market-wrap">
              <div class="price">{{ `$${parsePrice(item.price)}` }}</div>
              <div
                :class="[
                  'chg',
                  (item.change || '').startsWith('-') ? 'down' : 'up',
                ]"
              >
                {{ item.change }}
              </div>
            </div>
          </div>
        </div>
        <div class="tokens" v-else>
          <img
            :src="
              require(`../../assets/header/search/search-loading${
                isDarkMode ? '_dark' : ''
              }.png`)
            "
            v-if="swapMarketSearchStatus === 'loading'"
            class="loading"
          />
          <div
            v-else-if="swapMarketSearchStatus === 'noData'"
            class="tokens-no-data"
          >
            <img
              :src="
                require(`../../assets/header/search/search-empty${
                  isDarkMode ? '_dark' : ''
                }.png`)
              "
            />
            <div class="content">{{ $t('header.noSearchContent') }}</div>
          </div>
          <div v-else-if="swapMarketSearchStatus === 'data'">
            <div
              v-for="(key, index) in Object.keys(swapMarketTokenMap)"
              :key="`search-network-${index}`"
            >
              <div class="tokens-title">{{ key }}</div>
              <div
                class="token"
                v-for="(item, index1) in swapMarketTokenMap[key]"
                :key="`search-token-${index1}`"
                @click="selectSearchedToken(item)"
              >
                <div class="token-logo-wrap">
                  <img
                    :src="item.icon"
                    class="token-logo"
                    onerror="javascript:this.src='https://tokenpocket.cdn.bcebos.com/common.png';"
                  />
                  <img
                    v-if="item.networkIcon"
                    class="network-logo"
                    :src="item.networkIcon"
                    onerror="javascript:this.src='https://tokenpocket.cdn.bcebos.com/common.png';"
                  />
                </div>
                <div class="token-symbol-network-wrap">
                  <div class="symbol">{{ item.symbol }}</div>
                  <div class="name">{{ item.address.slice(0,5) + '...' + item.address.slice(-5) }}</div>
                </div>
                <div class="token-market-wrap">
                  <div class="price">{{ `$${item.price}` }}</div>
                  <div
                    :class="[
                      'chg',
                      (item.change || '').startsWith('-') ? 'down' : 'up',
                    ]"
                  >
                    {{ item.change }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Axios from 'axios';
import _ from 'lodash';
import { TP_SERVER_DOMAIN } from '../../utils/config';
import parseNumber from '../../mixins/parseNumber';
import darkMode from '../../mixins/darkMode';

const debounce = function (fn, delay) {
  let timer;
  return function (...args) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => fn.apply(this, args), delay);
  };
};

export default {
  data() {
    return {
      searchTokensVisible: false,
      swapMarketSearchStatus: '',
      swapMarketSearchInput: '',
      swapMarketTokenMap: [],
      searchHistoryTokens: [],
      hotTokenList: [],
      debouncerSearch: null,
      marketSwitchPanelVisible: false,
    };
  },
  mixins: [parseNumber, darkMode],
  computed: {
    ...mapGetters(['isDarkMode', 'isMobileSize']),
    deleteImg() {
      let str = ''
      if (this.isDarkMode) {
        str = '-dark'
      }
      return require(`../../assets/header/search/delete${str}.png`)
    },
    isTrue() {
      return !this.swapMarketSearchInput
    }
  },
  watch: {
    searchTokensVisible(val) {
      document.body.style.overflow = val ? 'hidden' : 'auto';
      if (val) {
        this.getHotSwapTokens();
        this.setHistoryTokens();
      }
    },
  },
  created() {
    this.getHistoryTokens();
  },
  mounted() {
    document.body.addEventListener('click', () => {
      // this.walletPanelVisible = false;
      // this.pairPanelVisible = false;
      // this.marketSwitchPanelVisible = false;
      // this.appSettingsVisible = false;
    });
  },
  methods: {
    onDelete() {
      localStorage.removeItem('searchHistoryTokens')
      this.searchHistoryTokens = []
    },
    getHistoryTokens() {
      // 代币行情搜索历史
      if (localStorage.getItem('searchHistoryTokens')) {
        try {
          this.searchHistoryTokens = JSON.parse(
            localStorage.getItem('searchHistoryTokens')
          );
        } catch (err) {
          console.log(err);
        }
      }
    },
    searchSwapMarket(input) {
      Axios.get(TP_SERVER_DOMAIN + '/v1/swap_market/search', {
        params: { key: input },
      })
        .then((res) => {
          if (this.swapMarketSearchInput !== input) return;
          if (
            res.status === 200 &&
            res.data &&
            res.data.data &&
            res.data.data.length
          ) {
            const map = {};
            const list = res.data.data;
            list.forEach((item) => {
              const change = Math.round(item.chg * 10000) / 100;
              item.change = `${item.chg > 0 ? '+' : ''}${change}%`;
              item.price = this.parsePrice(item.price);
              // console.log('network', item.network);
              try {
                item.networkIcon = require(`../../assets/header/search/${item.network}.png`)
              } catch (error) {
                item.networkIcon = ''
              }
              if (!map[item.blockchain_name]) {
                map[item.blockchain_name] = [item];
              } else {
                map[item.blockchain_name].push(item);
              }
            });
            this.swapMarketTokenMap = map;
            this.swapMarketSearchStatus = 'data';
          } else {
            this.swapMarketSearchStatus = 'noData';
          }
        })
        .catch((err) => {
          console.log(err);
          this.swapMarketSearchStatus = 'noData';
        });
    },
    getHotSwapTokens() {
      Axios.get(TP_SERVER_DOMAIN + '/v1/swap_market/hot_list').then((res) => {
        if (res.status === 200 && res.data && res.data.data) {
          const list = res.data.data.slice(0, 10);
          list.forEach((item) => {
            const change = Math.round(item.chg * 10000) / 100;
            item.change = `${item.chg > 0 ? '+' : ''}${change}%`;
            try {
              item.networkIcon = require(`../../assets/header/search/${item.network}.png`)
            } catch (error) {
              item.networkIcon = ''
            }
          });
          this.hotTokenList = list;
        }
      });
    },
    setHistoryTokens() {
      if (!this.searchHistoryTokens || !this.searchHistoryTokens.length) return;
      this.searchHistoryTokens.forEach((historyToken) => {
        Axios.get(TP_SERVER_DOMAIN + '/v1/swap_market/search', {
          params: { key: historyToken.address },
        }).then((res) => {
          if (
            res.status === 200 &&
            res.data &&
            res.data.data &&
            res.data.data.length
          ) {
            const token = res.data.data.find(
              (item) =>
                item.address === historyToken.address &&
                item.network === historyToken.network
            );
            const change = Math.round(token.chg * 10000) / 100;
            token.change = `${token.chg > 0 ? '+' : ''}${change}%`;
            for (let key in token) {
              historyToken[key] = token[key];
            }
            localStorage.setItem(
              'searchHistoryTokens',
              JSON.stringify(this.searchHistoryTokens)
            );
          }
        });
      });
    },
    selectSearchedToken(info) {
      // console.log('info', info);
      let isInHistory = false;
      console.log('searchHistoryTokens', this.searchHistoryTokens);
      this.searchHistoryTokens.forEach((item) => {
        if (
          item.network === info.network &&
          (item.address || '').toLowerCase() ===
            (info.address || '').toLowerCase()
        ) {
          isInHistory = true;
        }
      });
      if (!isInHistory) {
        this.searchHistoryTokens.unshift(info);
      }
      if (this.searchHistoryTokens.length > 2) {
        this.searchHistoryTokens.pop();
      }
      localStorage.setItem(
        'searchHistoryTokens',
        JSON.stringify(this.searchHistoryTokens)
      );
      const { network, address, symbol } = info;

      window.open(
        `https://swap.transit.finance/?locale=${this.$i18n.locale}&theme=${this.darkModeTheme}&inputChain=ETH&inputSymbol=ETH&outputChain=${network}&outputCurrency=${address}&outputSymbol=${symbol}&market=1#/`
      );
    },
    showSearchTokens() {
      // 这里重新从本地拿一下搜索历史
      this.getHistoryTokens();
      // this.isShowMenu = false;
      this.searchTokensVisible = true;
      document.documentElement.scrollTop = '0';
    },
    swapMarketInputChange() {
      if (!this.swapMarketSearchInput) {
        this.swapMarketTokenMap = {};
        return;
      }
      this.swapMarketSearchStatus = 'loading';
      this.debounceQuerySearch(this.swapMarketSearchInput);
    },
    debounceQuerySearch() {
      if (!this.debouncerSearch) {
        this.debouncerSearch = debounce(this.searchSwapMarket, 500);
      }

      this.debouncerSearch(...arguments);
    },
  },
};
</script>

<style lang="less">
.search-token-container {
  position: relative;
  z-index: 99;
  height: 40px;
  width: 432px;
  // max-width: calc(100% - 600px);
  padding: 0;
  box-sizing: border-box;
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  &.english {
    max-width: calc(100% - 600px);
  }
  .search {
    display: block;
    height: 40px;
    width: 432px;
    width: 100%;
    // max-width: calc(100% - 550px);
    padding: 0;
    box-sizing: border-box;
    // margin-top: -4px;
    border: 1px solid var(--wrap-border);
    border-radius: 22px;
    background-image: url('../../assets/header/search/search.png');
    background-size: 24px 24px;
    background-position: 16px center;
    background-repeat: no-repeat;
    padding-left: 50px;
    padding-right: 16px;
    background-color: var(--wrap-bg);
    color: var(--color);
    // position: absolute;
    // left: 0;
    // right: 0;
    // // top: -3px;
    // margin: auto;
    // margin-top: 0;
    // max-width: none;

    &::placeholder {
      color: var(--no-data-color);
      font-size: 12px;
    }

    &.focus {
      position: fixed;
      left: 0;
      right: 0;
      top: 17px;
      margin: auto;
      margin-top: 0;
      z-index: 99;
      max-width: none;
      width: 432px;
    }

    // @media screen and (min-width: 1300px) {
    //   position: absolute;
    //   left: 0;
    //   right: 0;
    //   // top: -3px;
    //   margin: auto;
    //   margin-top: 0;
    //   max-width: none;
    // }
  }
  .search-result {
    &-wrap {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      position: fixed;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 89;
    }

    &-content {
      width: 434px;
      max-height: 624px;
      min-height: 120px;
      position: fixed;
      background-color: white;
      overflow-y: auto;
      scrollbar-width: 0;
      top: 67px;
      left: 0;
      right: 0;
      margin: auto;
      background-color: var(--search-bg);
      border: var(--wrap-border) 1px solid;
      box-shadow: 0px 40px 80px 0px rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      box-sizing: border-box;
      padding: 0 12px;

      &::-webkit-scrollbar {
        display: none;
      }

      .token-title-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left-title {
          font-weight: 500;
          font-size: 16px;
          padding: 0 11px;
          margin-top: 20px;
          margin-bottom: 12px;
        }
        img {
          margin-right: 11px;
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
      }

      .tokens {
        &-title {
          margin-top: 20px;
          margin-bottom: 12px;
          padding: 0 11px;
          font-weight: 500;
          font-size: 16px;
        }

        &-no-data {
          margin: 50px auto;
          text-align: center;

          img {
            width: 70px;
          }

          .content {
            font-size: 12px;
            margin-top: 10px;
            color: var(--no-data-color);
          }
        }
      }

      .token {
        height: 60px;
        padding: 0 11px;
        margin: 6px 0;
        border-radius: 16px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
          background-color: var(--odd-bg);
        }

        &-logo-wrap {
          position: relative;
          display: flex;
          align-items: center;
        }

        .token-logo-order {
          width: 14px;
          height: 14px;
        }

        .token-order {
          font-size: 13px;
          font-family: PingFang SC, PingFang SC-Regular;
          text-align: center;
          color: var(--color-1);
        }

        &-logo {
          margin-left: 12px;
          height: 40px;
          width: 40px;
          border-radius: 50%;
          border: 1px solid var(--swap-wrap-border);
        }

        .network-logo {
          position: absolute;
          right: 0;
          top: 26px;
          width: 18px;
          height: 18px;
          background: var(--search-bg);
          border: 1px solid var(--search-bg);
          border-radius: 11px;
          // box-shadow: var(--search-network-shadow);
        }

        &-symbol-network-wrap {
          margin-left: 21px;

          .symbol {
            font-size: 16px;
            font-weight: 500;
          }

          .name {
            font-size: 12px;
            color: var(--no-data-color);
          }
        }

        &-market-wrap {
          margin-left: auto;
          font-weight: 600;
          text-align: right;

          .price {
            font-size: 16px;
          }

          .chg {
            font-size: 12px;

            &.up {
              color: #03c288;
            }

            &.down {
              color: #e55036;
            }
          }
        }
      }

      .loading {
        width: 24px;
        animation: loading 1.5s linear infinite;
        display: block;
        margin: 48px auto 0;

        @keyframes loading {
          0% {
            transform: rotate(0);
          }

          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}
// @media screen and (min-width: 1300px) {
//   .search-token-container {
//     position: absolute;
//     left: 0;
//     right: 0;
//     margin: auto;
//   }
// }
// 平板样式
@media screen and (max-width: 1200px) {
  .search-token-container {

  }
}
@media screen and (max-width: 767px) {
  .search-token-container {
    width: 0;
    height: 0;
    .search {
      display: none;
    }
    .search-back-wrap {
      position: fixed;
      top: 0;
      left: 0;
      height: 69px;
      background-color: var(--bg);
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 16px;
      z-index: 9;

      img {
        height: 18px;
      }
      .mobile-search {
        display: block;
        width: auto;
        left: 0.8rem;
        right: 0.32rem;
      }
    }

    .search-result {
      &-wrap {
        top: 69px;
        bottom: 0;
        height: unset;
      }

      &-content {
        width: 100%;
        height: 100%;
        top: 0;
        max-height: unset;
        border-radius: 0;
        padding: 0 10.5px;
        position: sticky;
        border: none;
        box-shadow: none;

        .token-title-wrap {
          .left-title {
            margin-top: 15px;
            margin-bottom: 10px;
          }
        }

        .tokens {
          &-title {
            margin-top: 15px;
            margin-bottom: 10px;
            font-size: 16px;

            &.popular {
              margin-top: 22px;
            }
          }

          .token {
            margin: 5px 0;
            height: 54px;
            padding-left: 5.5px;
            padding-right: 5.5px;
            border-radius: 14px;

            &-logo {
              width: 34px;
              height: 34px;
            }

            .network-logo {
              top: 22px;
              width: 16px;
              height: 16px;
            }

            &-symbol-network-wrap {
              margin-left: 18px;

              .symbol {
                font-size: 14px;
              }

              .name {
                font-size: 12px;
                transform: scale(0.92);
                transform-origin: left center;
                margin-top: 1px;
              }
            }

            &-market-wrap {
              .price {
                font-size: 14px;
              }

              .chg {
                font-size: 12px;
                transform: scale(0.92);
                transform-origin: right bottom;
                margin-top: 1px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="contact-box">
    <div class="desc-wrap">
      <img src="../../assets/dialog/danger.png" alt="" />
      <span>{{ $t('home.modal_contact_us.text_1') }}</span>
    </div>
    <div class="contact-list">
      <div
        class="contact-item"
        v-for="(item, index) in contactList"
        :key="index"
      >
        <div class="contact-item-wrap">
          <div class="contact-left">
            <img class="icon" :src="item.icon" alt="" />
          </div>
          <div class="contact-right">
            <div class="name">{{ item.title }}</div>
            <div class="link">
              <span>{{ item.url }}</span>
              <img
                v-if="item.urlIcon"
                :src="item.urlIcon"
                @click="openUrl(item)"
                alt=""
              />
              <img
                v-if="item.copyIcon"
                :src="item.copyIcon"
                v-clipboard:copy="item.url"
                @click="openToast(item.url)"
                alt=""
              />
                <!-- v-clipboard:success="openToast(item.url)" -->
            </div>
          </div>
        </div>
        <div class="tips" v-if="item.tipsIcon">
          <img :src="item.tipsIcon" alt="" />
          <span>{{ item.tips }}</span>
        </div>
      </div>
    </div>
    <ToastCopy v-if="isToast" @closeToast="closeToast" />
  </div>
</template>

<script>
// Changelly vk4tudlykals8fd7   
// SWFT/Omni a50b0d97-b607-4a2c-91e5-f5629c1afb8b   

import ToastCopy from '../tools/ToastCopy.vue';
import toastCopy from '../../mixins/toastCopy';
export default {
  props: {
    contactList: {
      type: Array,
    },
  },
  components: { ToastCopy },
  mixins: [toastCopy],
  methods: {
    openUrl(item) {
      if (item.isTG) {
        this.$mitt.emit('tg-modal', { show: true, url: item.url })
      } else if (item.url) {
        window.open(item.url, 'blank')
      }
    },
  },
};
</script>

<style lang="less" scoped>
.contact-box {
  margin-top: 16px;
  .desc-wrap {
    display: flex;
    background: rgba(229, 80, 54, 0.07);
    border-radius: 12px;
    padding: 12px;
    word-break: break-word;
    img {
      width: 18px;
      height: 18px;
    }
    span {
      margin-left: 8px;
      font-size: 13px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #e55036;
      line-height: 18px;
    }
  }
  .contact-list {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px 0;
    .contact-item {
      background: none;
      border: 1px solid var(--border-1);
      border-radius: 12px;
      padding: 12px;
      .contact-item-wrap {
        display: flex;
        align-items: center;
        .contact-left {
          display: flex;
          .icon {
            width: 24px;
            height: 24px;
          }
        }
        .contact-right {
          margin-left: 24px;
          flex: 1;
          .name {
            font-size: 12px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            color: var(--color-8);
          }
          .link {
            margin-top: 6px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: var(--bg-8);
            border-radius: 8px;
            padding: 6px 12px;
            span {
              font-size: 12px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              color: #2980fe;
              word-break: break-word;
            }
            img {
              margin-left: 10px;
              width: 14px;
              height: 14px;
              cursor: pointer;
            }
          }
        }
      }
      .tips {
        margin: 4px 0 0 48px;
        display: flex;
        img {
          margin-top: 4px;
          width: 10px;
          height: 10px;
        }
        span {
          margin-left: 4px;
          font-size: 12px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #f28f26;
          line-height: 16px;
          transform-origin: left center;
          transform: scale(0.92);
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .contact-box {
    margin-top: 19px;
    .desc-wrap {
      padding: 10px 12px;
      border-radius: 12px;
      img {
        width: 18px;
        height: 18px;
      }
      span {
        margin-left: 8px;
        font-size: 13px;
        line-height: 18px;
      }
    }
    .contact-list {
      margin-top: 8px;
      gap: 8px 0;
      .contact-item {
        border-radius: 12px;
        padding: 12px 12px 12px 24px;
        .contact-item-wrap {
          // padding: 24px 24px 24px 48px;
          .contact-left {
            .icon {
              width: 24px;
              height: 24px;
            }
          }
          .contact-right {
            margin-left: 24px;
            .name {
              font-size: 12px;
            }
            .link {
              padding: 7px 12px;
              border-radius: 8px;
              span {
                font-size: 12px;
              }
              img {
                width: 14px;
                height: 14px;
              }
            }
          }
        }
        .tips {
          margin: 9px 0 0 48px;
          img {
            margin-top: 1px;
            width: 10px;
            height: 10px;
          }
          span {
            margin-left: 5px;
            font-size: 12px;
            font-family: PingFang SC, PingFang SC-Regular;
            color: #f28f26;
            line-height: 12px;
          }
        }
      }
    }
  }
}
</style>

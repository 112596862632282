<template>
  <div class="header">
    <div class="header-left">
      <img
        :src="logoImg"
        alt="Transit Markets"
        class="logo markets-logo"
        @click="goHome"
      />
      <ul class="nav-list">
        <li>
          <a @click="goSwap" style="cursor: pointer">
            {{ $t('swap') }}
          </a>
        </li>
        <li class="markets">
          <a @click="goMarkets" style="cursor: pointer">
            {{ $t('header.markets') }}
          </a>
          <!-- <img src="../../assets/header/soon.png" alt="" /> -->
        </li>
        <li>
          <a
            :href="transitBuyUrl"
            target="_blank"
            >{{ $t('header.buyToken') }}</a
          >
        </li>
        <!-- <li class="inscribe-tool">
          <a
            :href="inscribeToolUrl"
            target="_blank"
            >{{ $t('header.inscribeTool') }}</a
          >
        </li> -->
      </ul>
      <SearchToken v-if="$route.path !== '/terms' && $route.path !== '/privacy-policy'" ref="searchToken" />
    </div>

    <div class="header-right">
      <img
        :src="
          require(`../../assets/header/search/swap-token-search${
            isDarkMode ? '_dark' : ''
          }.png`)
        "
        class="mobile-search"
        :class="{ hidden: $route.path === '/terms' || $route.path === '/privacy-policy' }"
        @click="showSearchTokens"
      />
      <div class="menu-wrap">
        <img class="menu-img" :src="moreImg" @click.stop="onMenu" alt="" />
        <div 
          class="app-settings-panel with-triangle" 
          :class="{ 'app-setting-panel-ani': isAni}" 
          v-if="appSettingsVisible"
        >
          <!-- v-if="appSettingsVisible" -->
          <AppSettingsPanel />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import tp from 'tp-js-sdk';
import _ from 'lodash';

import Switcher from '../tools/Switcher.vue';
import SearchToken from '../tools/SearchToken.vue';
import AppSettingsPanel from '../tools/AppSettingsPanel.vue';
import darkMode from '../../mixins/darkMode';
import link from '../../mixins/link';

export default {
  data() {
    return {
      appSettingsVisible: false,
      isAni: false
    };
  },
  components: { Switcher, SearchToken, AppSettingsPanel },
  mixins: [darkMode, link],

  computed: {
    ...mapGetters(['isMobileSize', 'isDarkMode']),
    inscribeToolUrl() {
      return `https://tp-lab.tokenpocket.pro/insc/?locale=${this.$i18n.locale}&theme=${this.darkModeTheme}#/`
    },
    // transitBuyUrl() {
    //   return `https://buy.transit.finance/?locale=${this.$i18n.locale}&theme=${this.darkModeTheme}`
    // },
    logoImg() {
      return require(`../../assets/home/Transit${this.darkModeImg}.png`);
    },
    moreImg() {
      return require(`../../assets/header/more${this.darkModeImg}.png`);
    },
  },
  watch: {
    appSettingsVisible(n) {
      if (n) {
        setTimeout(() => {
          this.isAni = true
        }, 0);
      } else {
        this.isAni = false
      }
    }
  },
  created() {},
  mounted() {
    document.body.addEventListener('click', () => {
      this.appSettingsVisible = false;
      // this.$store.commit('setAppSettingsVisible', false);
      // this.$store.commit('setMarketSwitchPanelVisible', false);
    });
  },
  methods: {
    onMenu() {
      if (this.isMobileSize) {
        this.$store.commit('setAppSettingDialog', true);
      } else {
        this.appSettingsVisible = !this.appSettingsVisible;
      }
    },
    openContactUs() {
      this.$emit('openContactUs');
    },

    showSearchTokens() {
      this.$refs.searchToken.showSearchTokens();
    },
    onMobileMenu() {
      this.onSwitchMode();
      this.$store.commit('setShowMenu', false);
    },
    setMarketVisible() {
      this.$store.commit('setMarketVisible', !this.marketVisible);
    },
    onSwitchMode() {
      window.localStorage.setItem('darkmode', !this.isDarkMode ? '1' : '0');
      this.$store.commit('setDarkMode', !this.isDarkMode);
      this.setMode();
    },
    setMode() {
      if (this.isDarkMode) {
        document.documentElement.setAttribute('theme', 'dark');
      } else {
        document.documentElement.removeAttribute('theme');
      }
    },
    switchLang() {
      this.$i18n.locale = this.$i18n.locale === 'zh' ? 'en' : 'zh';
      // this.isShowMenu = false;
      this.$store.commit('setShowMenu', false);
      localStorage.setItem('lang', this.$i18n.locale);
    },
    goHome() {
      if (this.$route.path !== '/') {
        this.$router.push('/');
      }
    },
    goMarkets() {
      window.open(this.swapMarketUrl);
    },
    goSwap() {
      window.open(this.swapUrl);
    },
  },
};
</script>

<style lang="less">
.header {
  max-width: 1360px;
  margin: 0 auto;
  padding: 0.2rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header-left {
    display: flex;
    align-items: center;
    flex: 1;
    .logo {
      height: 24px;
      margin: 4px 0 6px 4px;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
    }
    .nav-list {
      position: relative;
      top: 1px;
      padding: 0;
      line-height: 32px;
      display: flex;
      margin: 0 8px 0 20px;

      li {
        padding: 0;
        display: flex;
        align-items: center;
        margin: 0 8px;
        a {
          text-decoration: none;
          color: var(--color-1);
          font-size: 15px;
          font-family: PingFang SC, PingFang SC-Medium;
          // font-family: 'Inter-Regular';
          font-weight: 400;
          // cursor: pointer;
          &:hover {
            color: var(--color-5);
          }
        }
        img {
          margin-left: 5px;
          width: 29px;
          height: 14px;
        }
      }
    }
  }
  .header-right {
    display: flex;
    .mobile-search {
      display: none;
    }
    .menu-wrap {
      position: relative;
      display: flex;
      .menu-img {
        width: 0.36rem;
        height: 0.36rem;
        cursor: pointer;
      }
      .app-settings-panel {
        position: absolute;
        top: 45px;
        right: 0;
        min-width: 168px;
        background: var(--wrap-bg);
        box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.2);
        // padding: 16px 20px 15px;
        padding: 12px 12px 16px;
        z-index: 9;
        border-radius: 12px;
        opacity: 0;
        transform: scale(0);
        transform-origin: right top;
        transition: opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s, transform 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
        &.app-setting-panel-ani {
          opacity: 1;
          transform: scale(1);
        }
        &::after {
          right: 10px;
        }
      }
    }
  }
  .nav-list-m {
    .optional-wrap {
      height: 28px;
      display: inline-block;
      .optional-img {
        width: 24px;
        height: 24px;
        vertical-align: middle;
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .header {
    margin: 0 30px;
  }
}

@media screen and (max-width: 1370px) {
  .header {
    .header-left {
      .nav-list {
        .inscribe-tool {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .header {
    .header-left {
      .logo {
        height: 32px;
      }
      .nav-list {
        .markets {
          display: none;
        }
      }
    }
    .header-right {
      .menu-wrap {
        .menu-img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
@media screen and (max-width: 1050px) {
  .header {
    .header-left {
      .nav-list {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .header {
    margin: 0;
    padding: 0.2rem 0.32rem;
    .header-left {
      .nav-list {
        display: none;
      }
      .logo {
        height: 30px;
      }
    }
    .header-right {
      align-items: center;
      .mobile-search {
        display: block;
        margin-right: 16px;
        width: 30px;
        height: 30px;
        &.hidden {
          display: none;
        }
      }
      .menu-wrap {
        .menu-img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
</style>

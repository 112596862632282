import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

// let defaultLangStr = navigator.language;
// let defaultPath = "/en/";

// if (defaultLangStr.indexOf("zh") >= 0) {
//   defaultPath = "/zh/";
// }

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/donation',
    name: 'Donation',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Donation.vue'),
  },
  {
    path: '/stake',
    name: 'Stake',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Stake.vue'),
  },
  {
    path: '/fee',
    name: 'Fee',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Fee.vue'),
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Terms.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/PrivacyPolicy.vue'),
  },
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}


const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes,
});

export default router;

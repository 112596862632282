<template>
  <div id="app">
    <div class="bg-under"></div>
    <router-view />
    <BottomDialog v-if="isAppSettingDialog">
      <div class="dialog-content">
        <AppSettingsPanel />
      </div>
    </BottomDialog>
    <modal
      height="auto"
      classes="contact-us-modal"
      color="#49d663"
      :adaptive="true"
      width="88%"
      :maxWidth="378"
      name="contact-us-panel"
    >
      <div class="modal-content">
        <div class="title">{{ $t('home.modal_contact_us.title') }}</div>
        <ContactBox
          :contactList="contactList"
          @transit="openTransitModal"
        />
        <!-- <div class="desc">
          {{ $t('home.modal_contact_us.text_1') }}
        </div>
        <ContactUsList />
        <div class="contact-transit-buy">
          <div class="title">{{ $t('home.modal_contact_us.text_2') }}</div>
          <a href="https://opserver.zendesk.com/hc/en-us/requests/new" target="_blank" class="button">
            <div class="contact-img" :class="{ isDark: isDarkMode }"></div>
          </a>
        </div> -->
      </div>
    </modal>
    <TGModal v-if="showTGModal" @close="closeTGModal" :url="tgUrl" />
  </div>
</template>

<script>
import axios from 'axios';
import BottomDialog from './components/tools/BottomDialog.vue';
import AppSettingsPanel from './components/tools/AppSettingsPanel.vue';
import ContactUsList from './components/tools/ContactUsList.vue';
import ContactBox from './components/tools/ContactBox.vue';
import TGModal from './components/tools/TGModal.vue';
import { setMode } from './utils';
import { mapGetters } from 'vuex';
import darkModeImg from './mixins/darkMode';

const transitEmail = 'service@transit.finance';
const transitTelegram = 'https://t.me/Transit_Finance';
const transitBuy = 'https://opserver.zendesk.com/hc/en-us/requests/new';

window.onload = function () {
  // 阻止双击放大行为
  var lastTouchEnd = 0;
  document.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });
  document.addEventListener(
    'touchend',
    function (event) {
      var now = new Date().getTime();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    },
    false
  );

  // 阻止双指进行缩放大行为
  document.addEventListener('gesturestart', function (event) {
    event.preventDefault();
  });
};
export default {
  components: { BottomDialog, AppSettingsPanel, ContactUsList, ContactBox, TGModal },
  mixins: [darkModeImg],
  data() {
    return {
      isAni: false,
      showTGModal: false,
      tgUrl: '',
    }
  },
  computed: {
    ...mapGetters(['isAppSettingDialog', 'isDarkMode']),
    contactList() {
      return [
        {
          icon: require(`./assets/dialog/email${this.darkModeImg}.png`),
          title: this.$t('home.modal_contact_us.serviceEmail'),
          url: transitEmail,
          copyIcon: require(`./assets/dialog/copy-hover.png`),
        },
        {
          icon: require(`./assets/dialog/telegram${this.darkModeImg}.png`),
          title: this.$t('home.modal_contact_us.telegram'),
          isTG: true,
          url: transitTelegram,
          urlIcon: require(`./assets/dialog/share.png`),
        },
        {
          icon: require(`./assets/home/contact${this.darkModeImg}.png`),
          title: this.$t('home.modal_contact_us.text_2'),
          url: transitBuy,
          urlIcon: require(`./assets/dialog/share.png`),
          tipsIcon: require(`./assets/dialog/tips.png`),
          tips: this.$t('home.modal_contact_us.tips'),
        },
      ];
    }
  },
  watch: {
    isAppSettingDialog(val) {
      if (val) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    }
  },
  created() {
    document.body.style.overflow = 'auto'
    axios.get('https://geoip.transit.finance/').then((res) => {
      if (res.status === 200 && res.data.compliant_area) {
        history.pushState({}, '');
        window.location = 'https://swap.transit.finance/region/index.html';
      }
    });
  },
  mounted() {
    this.systemChange();
    this.$store.commit(
      'setMobileSize',
      document.documentElement.clientWidth <= 767
    );
    window.onresize = () => {
      this.$store.commit(
        'setMobileSize',
        document.documentElement.clientWidth <= 767
      );
    };
    this.$mitt.on('contact-us', (res) => {
      const { show } = res;
      show ? this.show() : this.hide();
    });
    this.$mitt.on('tg-modal', (res) => {
      const { show, url } = res;
      this.hide()
      this.tgUrl = url
      show ? this.openTGModal() : this.closeTGModal();
    });
  },
  methods: {
    openTGModal() {
      this.showTGModal = true
    },
    closeTGModal() {
      this.showTGModal = false
    },
    openTransitModal() {},
    systemChange() {
      const isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)');
      if (isDarkTheme) {
        isDarkTheme.onchange = (e) => {
          // console.log('systemChange', isDarkTheme);
          const theme = localStorage.getItem('theme');
          let mode = false;
          if (theme && theme === 'auto') {
            console.log('auto');
            if (e.matches) {
              console.log('设置深色主题');
              mode = true;
            } else {
              console.log('设置浅色主题');
              mode = false;
            }
            this.$store.commit('setDarkMode', mode);
            setMode(mode);
          }
        };
      }
    },
    show() {
      // document.body.style.overflow = 'hidden'
      this.$modal.show('contact-us-panel');
    },
    hide() {
      // document.body.style.overflow = 'auto'
      this.$modal.hide('contact-us-panel');
    },
  },
};
</script>
<style lang="less">
#app {
  color: var(--color);
  .dialog-content {
    padding: 16px 16px 61px;
  }
  .contact-us-modal {
    padding: 32px 32px;
    background: var(--wrap-bg);
    border: 1px solid var(--border-3);
    border-radius: 24px;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
    .modal-content {
      .title {
        font-size: 20px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: center;
        color: var(--color-5);
        line-height: 28px;
      }
      .desc {
        margin-top: 16px;
        font-size: 15px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: center;
        color: #e55036;
        line-height: 21px;
      }
      .contact-transit-buy {
        margin-top: 0.4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        .button {
          margin-top: 0.16rem;
          width: 0.64rem;
          height: 0.64rem;
          border: 0.01rem solid var(--border-1);
          border-radius: 0.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .contact-img {
            width: 0.32rem;
            height: 0.3rem;
            background: url('./assets/home/contact.png') no-repeat 100% / contain;
            &.isDark {
              background: url('./assets/home/contact-dark.png') no-repeat 100% / contain;
            }
          }
        }
        .button:hover {
          background: #2980fe;
          border: 1px solid #2980fe;
          .contact-img {
            width: 0.32rem;
            height: 0.3rem;
            background: url('./assets/home/contact-dark.png') no-repeat 100% / contain;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  #app {
    .contact-us-modal {
      padding: 16px;
    }
  }
}
</style>

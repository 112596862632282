<template>
  <div class="contact-us-list">
    <a
      class="follow-item"
      :class="[item.class]"
      target="_blank"
      :href="item.url"
      v-for="(item, index) in contactUsList"
      :key="index"
    >
      <div class="footer-follow"></div>
    </a>
  </div>
</template>

<script>
import darkMode from '../../mixins/darkMode';

export default {
  mixins: [darkMode],
  computed: {
    contactUsList() {
      return [
        {
          url: 'https://twitter.com/TransitFinance',
          class: `follow-item-1${this.darkModeImg}`,
        },
        {
          url: 'https://t.me/Transit_Finance',
          class: `follow-item-2${this.darkModeImg}`,
        },
        {
          url: 'mailto:service@transit.finance',
          class: `follow-item-3${this.darkModeImg}`,
        },
      ];
    },
  },
};
</script>

<style lang="less" scoped>
.contact-us-list {
  display: flex;
  justify-content: center;
  margin: 32px 0 24px;
  .loop1(@i) when (@i > 0) {
    .follow-item-@{i} {
      .footer-follow {
        background: url('../../assets/footer/@{i}.png') no-repeat 100% / contain;
      }
      cursor: pointer;
      &:hover {
        background: #2980fe;
        border: 1px solid #2980fe;
        .footer-follow {
          background: url('../../assets/footer/@{i}-on.png') no-repeat 100% /
            contain;
        }
      }
    }
    .follow-item-@{i}-dark {
      .footer-follow {
        background: url('../../assets/footer/@{i}-dark.png') no-repeat 100% /
          contain;
      }
      cursor: pointer;
      &:hover {
        background: #2980fe;
        border: 1px solid #2980fe;
        .footer-follow {
          background: url('../../assets/footer/@{i}-on.png') no-repeat 100% /
            contain;
        }
      }
    }
    .loop1((@i - 1));
  }

  .loop1(3);
  .follow-item {
    width: 62px;
    height: 62px;
    border: 1px solid var(--border-1);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:nth-child(n + 2) {
      margin-left: 28px;
    }
    .footer-follow {
      width: 32px;
      height: 32px;
    }
  }
}
@media screen and (max-width: 767px) {
  .contact-us-list {
    margin: 22px 0 0;
    .loop1(@i) when (@i > 0) {
      .follow-item-@{i} {
        .footer-follow {
          background: url('../../assets/footer/@{i}.png') no-repeat 100% /
            contain;
        }
        cursor: pointer;
        &:hover {
          background: none;
          border: 1px solid var(--border-1);
          .footer-follow {
            background: url('../../assets/footer/@{i}.png') no-repeat 100% /
              contain;
          }
        }
      }
      .follow-item-@{i}-dark {
        .footer-follow {
          background: url('../../assets/footer/@{i}-dark.png') no-repeat 100% /
            contain;
        }
        cursor: pointer;
        &:hover {
          background: none;
          border: 1px solid var(--border-1);
          .footer-follow {
            background: url('../../assets/footer/@{i}-dark.png') no-repeat 100% /
              contain;
          }
        }
      }
      .loop1((@i - 1));
    }

    .loop1(3);
    .follow-item {
      border-radius: 12px;
      width: 44px;
      height: 44px;
      .footer-follow {
        width: 24px;
        height: 24px;
      }
    }
  }
}
</style>

import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['isDarkMode']),
    darkModeImg() {
      let str = '';
      if (this.isDarkMode) {
        str = '-dark';
      }
      return str;
    },
    darkModeTheme() {
      let str = 'light';
      if (this.isDarkMode) {
        str = 'dark';
      }
      return str;
    },
  },
};

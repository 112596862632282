<template>
  <div class="home-container">
    <div class="first-screen">
      <div class="background-content">
        <div
          class="blue-filter"
          :class="{ active: isBgImgAnimation && !isMobileSize }"
        ></div>
        <div
          class="bg-img-wrap"
          @mouseenter="bgImgMouseEnter"
          @mouseleave="bgImgMouseLeave"
          :class="{ 'bg-img-animation': isBgImgAnimation && !isMobileSize }"
        >
          <div
            class="bg-img-mask"
            :class="{ 'buy-mask': bgImgIndex === 3 }"
            v-show="!isBgImgAnimation || isMobileSize"
          ></div>
          <img :src="bgImg" alt="" />
        </div>
        <div class="bar-item-wrap">
          <BarItem
            v-for="(item, index) in barList"
            :key="index"
            :barItem="item"
          />
        </div>
        <div class="opacity-bg"></div>
      </div>
      <div class="root-content">
        <!-- <div class="opacity-bg"></div> -->
        <div class="desc">{{ $t('home.title_1') }}</div>
        <div class="desc desc-2">{{ $t('home.title_2') }}</div>
        <div class="button-list">
          <div
            class="button-item bg-color-overlay"
            v-for="(item, index) in buttonList"
            :key="index"
            @mouseenter="onMouseEnter(index)"
            @mouseleave="onMouseLeave"
            @click="onButton(item.url)"
          >
            <img :src="item.icon" alt="" />
            <span>{{ item.label }}</span>
          </div>
        </div>
        <div class="start-trading bg-color-overlay" @click="goSwap">
          {{ $t('home.startTrading') }}
        </div>
        <div class="view-more" @click="onViewMore">
          <span>{{ $t('home.viewMore') }}&#128071;</span>
        </div>
      </div>
    </div>
    <div class="transition-bg"></div>
    <div class="middle-screen" ref="middle-screen">
      <div class="features-list">
        <a class="feature-item swap-item bg-color-overlay" @click="goMarkets">
          <div class="title">{{ $t('home.markets.title') }}</div>
          <div class="label-text label" v-if="$i18n.locale === 'zh'">
            获取实时数据，发现热门代币，并通过市场 &#128200;&#128201;
            功能做出更智慧的交易决策。
          </div>
          <div class="label-text label" v-else>
            Get real-time insights to discover hot tokens and trade smarter with
            the Market &#128200;&#128201; feature.
          </div>
          <img
            class="middle-market"
            src="../../assets/home/middle-Market.png"
            alt=""
          />
          <!-- <div class="label-text label coming-soon">{{ $t('home.markets.text_1') }}</div> -->
          <img class="enter" src="../../assets/home/enter.png" alt="" />
        </a>
        <a
          :href="transitBuyUrl"
          target="_blank"
          class="feature-item buy-token bg-color-overlay"
        >
          <div class="title">{{ $t('home.onRamp.title') }}</div>
          <div class="label-text label">
            {{ $t('home.onRamp.text') }}
          </div>
          <img
            class="middle-market"
            src="../../assets/home/middle-OnRamp.png"
            alt=""
          />
          <img class="enter" src="../../assets/home/enter.png" alt="" />
        </a>
        <a
          :href="swapUrl"
          target="_blank"
          class="feature-item swap-bridge-item bg-color-overlay"
        >
          <div class="title">{{ $t('home.swap_bridge.title') }}</div>
          <div class="label-text label">
            {{ $t('home.swap_bridge.text') }}
          </div>
          <img
            class="middle-market swap-bridge-img"
            src="../../assets/home/Swap-Bridge-bg-780.png"
            alt=""
          />
          <img class="enter" src="../../assets/home/enter.png" alt="" />
        </a>
        <div class="feature-item liquidity-options" ref="liquidity-options">
          <div class="title">{{ $t('home.liquidity_options.title') }}</div>
          <div class="label-text label">
            {{ $t('home.liquidity_options.text_1') }}
          </div>
          <div class="label-text label">
            {{ $t('home.liquidity_options.text_2') }}
          </div>
          <div class="list">
            <div
              class="item"
              v-for="(item, index) in liquidityOptions"
              :key="index"
            >
              <!-- <span ref="count-node">{{ item.amount }}</span> -->
              <span ref="count-node">{{ item.endVal }}</span>
              <span>{{ item.text }}</span>
            </div>
          </div>
          <img class="data-bg" src="../../assets/home/data-bg.png" alt="" />
        </div>
        <div class="feature-item other-feature">
          <div class="label-text">{{ $t('home.other_features.title') }}</div>
          <div class="other-feature-list">
            <div
              class="other-feature-item"
              :class="{ active: item.type === curParent.type && item.show }"
              v-for="(item, index) in otherFeatureList"
              :key="index"
              @mouseenter="onMouseEnter1(item)"
              @mouseleave="onMouseLeave1(item)"
              @click.stop="onMobile(item)"
            >
              <div class="left">
                <img :src="item.icon" alt="" />
                <span>{{ item.title }}</span>
              </div>
              <img class="unfold" src="../../assets/home/unfold.png" alt="" />
              <div class="child-list-bg">
                <div
                  class="child-list"
                  :class="{ 'child-list-ani': isAni }"
                  v-show="item.show"
                >
                  <a
                    class="child-item bg-color-overlay"
                    v-for="(cItem, cIndex) in item.childList"
                    :key="cIndex"
                    :href="cItem.url && cItem.url"
                    target="_blank"
                    @click="onChild(cItem)"
                  >
                    <div class="child-left">
                      <img :src="cItem.subIcon" alt="" />
                      <span>{{ cItem.subtitle }}</span>
                    </div>
                    <img
                      class="down"
                      src="../../assets/home/enter-2.png"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <img class="other-bg" src="../../assets/home/Other-bg.png" alt="" />
        </div>
      </div>
      <div class="get-start-bar">
        <div class="bar-left">
          <div class="bar-title">
            {{ $t('home.get_start.text_1') }}
          </div>
          <div class="bar-desc">
            {{ $t('home.get_start.text_2') }} &#127891;
          </div>
        </div>
        <div class="start-button bg-color-overlay" @click="goSwap">
          {{ $t('home.get_start.text_3') }}
        </div>
        <img
          class="get-start-bg"
          src="../../assets/home/get-start-bg.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CountUp } from 'countup.js';
import darkMode from '../../mixins/darkMode';
import link from '../../mixins/link';
import { BAR_LIST } from '../../utils/barList';
import BarItem from '../tools/BarItem.vue';

export default {
  components: { BarItem },
  data() {
    return {
      bgImgIndex: 0,
      barList: BAR_LIST,
      timerId: null,
      otherFeatureListZh: [
        {
          title: 'NFT',
          icon: require('../../assets/home/NFT.png'),
          show: false,
          type: 'NFT',
          childList: [
            {
              subtitle: 'NFT市场',
              subIcon: require('../../assets/home/NFT-1.png'),
              url: 'https://nft.transit.finance/waitlist/#/',
            },
            {
              subtitle: 'Transit NFT',
              subIcon: require('../../assets/home/NFT-2.png'),
              url: 'https://nft.transit.finance/#/',
            },
            {
              subtitle: '设计大赛',
              subIcon: require('../../assets/home/NFT-3.png'),
              url: 'https://tp-lab.tokenpocket.pro/mint-skin-2/index.html#/',
            },
          ],
        },
        {
          title: '治理',
          icon: require('../../assets/home/Governance.png'),
          show: false,
          type: 'Governance',
          childList: [
            {
              subtitle: '治理',
              subIcon: require('../../assets/home/DAO.png'),
              locale: true,
              routePath: '/stake',
            },
            {
              subtitle: '捐赠',
              subIcon: require('../../assets/home/Donation.png'),
              locale: true,
              routePath: '/donation',
            },
            {
              subtitle: '费率',
              locale: true,
              subIcon: require('../../assets/home/fee.png'),
              routePath: '/fee',
            },
          ],
        },
      ],
      otherFeatureListEn: [
        {
          title: 'NFT',
          icon: require('../../assets/home/NFT.png'),
          show: false,
          type: 'NFT',
          childList: [
            {
              subtitle: 'NFT MarketPlace',
              subIcon: require('../../assets/home/NFT-1.png'),
              url: 'https://nft.transit.finance/waitlist/#/',
            },
            {
              subtitle: 'Transit NFT',
              subIcon: require('../../assets/home/NFT-2.png'),
              url: 'https://nft.transit.finance/#/',
            },
            {
              subtitle: 'NFT Design Competition',
              subIcon: require('../../assets/home/NFT-3.png'),
              url: 'https://tp-lab.tokenpocket.pro/mint-skin-2/index.html#/',
            },
          ],
        },
        {
          title: 'Governance',
          icon: require('../../assets/home/Governance.png'),
          show: false,
          type: 'Governance',
          childList: [
            {
              subtitle: 'DAO',
              subIcon: require('../../assets/home/DAO.png'),
              locale: true,
              routePath: '/stake',
            },
            {
              subtitle: 'Donation',
              subIcon: require('../../assets/home/Donation.png'),
              locale: true,
              routePath: '/donation',
            },
            {
              subtitle: 'Fee',
              subIcon: require('../../assets/home/fee.png'),
              locale: true,
              routePath: '/fee',
            },
          ],
        },
      ],
      curParent: '',
      isBgImgAnimation: false,
      isAni: false,
    };
  },
  mixins: [darkMode, link],
  mounted() {
    document.body.addEventListener('click', () => {
      this.resetOtherFeatureList();
      this.isAni = false;
      this.curParent = '';
    });
    this.$nextTick(() => {
      this.initIntersectionObserver();
      // this.createCountUp();
    });
  },
  beforeDestroy() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  },

  computed: {
    ...mapGetters(['isMobileSize']),
    bgImg() {
      return require(`../../assets/home/bg-img-${this.bgImgIndex}${this.darkModeImg}.png`);
    },
    buttonList() {
      return [
        {
          icon: require(`../../assets/home/Swap${this.darkModeImg}.png`),
          label: this.$t('home.swap'),
          url: this.swapUrl,
        },
        {
          icon: require(`../../assets/home/Bridge${this.darkModeImg}.png`),
          label: this.$t('home.bridge'),
          url: this.swapUrl,
        },
        {
          icon: require(`../../assets/home/Market${this.darkModeImg}.png`),
          label: this.$t('home.markets.title'),
          url: this.swapMarketUrl,
        },
        {
          icon: require(`../../assets/home/OnRamp${this.darkModeImg}.png`),
          label: this.$t('home.onRamp.title'),
          url: this.transitBuyUrl,
        },
      ];
    },
    otherFeatureList() {
      if (this.$i18n.locale === 'zh') {
        return this.otherFeatureListZh;
      } else {
        return this.otherFeatureListEn;
      }
    },
    liquidityOptions() {
      return [
        {
          amount: '31',
          endVal: 31,
          text: this.$t('home.liquidity_options.Chains'),
        },
        {
          amount: '122',
          endVal: 122,
          text: this.$t('home.liquidity_options.DEXs'),
        },
        {
          amount: '4',
          endVal: 4,
          text: this.$t('home.liquidity_options.Bridges'),
        },
        {
          amount: '7',
          endVal: 7,
          text: this.$t('home.liquidity_options.Ramps'),
        },
        {
          amount: '5M+',
          endVal: 5,
          text: this.$t('home.liquidity_options.Tokens'),
        },
      ];
    },
  },
  methods: {
    goMarkets() {
      window.open(this.swapMarketUrl);
    },
    initIntersectionObserver() {
      this.intersectionObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entrie) => {
            if (entrie.intersectionRatio >= 0.3) {
              // console.log('entrie', entrie);
              // 可见百分比
              if (
                entrie.target.className === 'feature-item liquidity-options'
              ) {
                // console.log('进入');
                this.createCountUp();
              }
              // 只在挂载的时候执行一次
              // this.intersectionObserver.unobserve(entrie.target)
            } else if (entrie.intersectionRatio === 0) {
              // 完全不可见
              if (
                entrie.target.className === 'feature-item liquidity-options'
              ) {
                // console.log('离开');
              }
            }
          });
        },
        { threshold: [0, 0.3] }
      );

      if (this.$refs) {
        // console.log('refs', this.$refs);
        this.intersectionObserver.observe(this.$refs['liquidity-options']);
      }
    },
    createCountUp() {
      const countNodeList = this.$refs['count-node'];
      // console.log('refs', countNodeList);
      countNodeList.forEach((node, index) => {
        let options = { startVal: 0 };
        if (index === 4) {
          options.suffix = 'M+';
        }
        const instance = new CountUp(
          node,
          this.liquidityOptions[index].endVal,
          options
        );
        instance.start();
      });
    },
    bgImgMouseEnter() {
      this.playAnimation();
      this.isBgImgAnimation = true;
    },
    bgImgMouseLeave() {
      this.resetAnimation();
      this.isBgImgAnimation = false;
    },
    onButton(url) {
      if (url) {
        window.open(url);
      } else {
        this.$toast({
          text: this.$t('comingSoon'),
        });
      }
    },
    /** 开始播放K线动画 */
    playAnimation() {
      const mask = document.querySelectorAll('.mask');
      mask[0].classList.add('ani');
      let count = 1;
      // 50 80
      this.timerId = setInterval(() => {
        if (mask[count]) {
          mask[count].classList.add('ani');
          count++;
          // if (count === 50) {
          //   count += 30;
          // } else {
          //   count++;
          // }
        } else {
          clearInterval(this.timerId);
          count = 1;
        }
      }, 5);
    },
    /** 重置K线动画 */
    resetAnimation() {
      if (this.timerId) {
        clearInterval(this.timerId);
      }
      const mask = document.querySelectorAll('.mask');
      if (mask) {
        mask.forEach((item) => {
          item.classList.remove('ani');
        });
      }
    },
    onViewMore() {
      this.$el.querySelector('.middle-screen').scrollIntoView({
        behavior: 'smooth', // 平滑过渡
        block: 'start', // 居中
      });
    },
    goSwap() {
      window.open(this.swapUrl);
    },
    onMouseEnter(index) {
      if (!this.isMobileSize) {
        this.bgImgIndex = index;
      }
      // this.playAnimation();
    },
    onMouseLeave() {
      // this.resetAnimation();
    },
    onParent(parent) {
      // console.log('parent', parent);
      // this.resetOtherFeatureList();
      if (!this.curParent.type || this.curParent.type === parent.type) {
        parent.show = !parent.show;
        if (parent.show) {
          setTimeout(() => {
            this.isAni = true;
          }, 0);
        } else {
          this.isAni = false;
        }
      } else {
        this.resetOtherFeatureList();
        parent.show = true;
        this.isAni = false;
        setTimeout(() => {
          this.isAni = true;
        }, 0);
      }
      this.curParent = parent;
      // this.curIndex= parent.index
    },
    onChild(child) {
      // console.log('child', child);
      if (child.routePath) {
        this.$router.push(child.routePath);
      }
    },
    onMobile(parent) {
      if (this.isMobileSize) {
        this.onParent(parent);
      }
    },
    onMouseEnter1(parent) {
      if (!this.isMobileSize) {
        this.onParent(parent);
      }
    },
    onMouseLeave1() {
      if (!this.isMobileSize) {
        this.resetOtherFeatureList();
      }
    },
    resetOtherFeatureList() {
      this.otherFeatureListZh.forEach((item) => (item.show = false));
      this.otherFeatureListEn.forEach((item) => (item.show = false));
    },
  },
};
</script>

<style lang="less">
.home-container {
  width: 100%;
  @keyframes bgImgAnimation {
    0% {
      transform: translate(-50%, 0);
    }
    100% {
      transform: translate(-50%, -50px);
    }
  }
  .first-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 30px);
    height: 680px;
    position: relative;
    .background-content {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 3;
      // top: 80px;
      top: 2%;
      .blue-filter {
        position: relative;
        z-index: 1;
        margin: 0 auto;
        width: 350px;
        height: 329px;
        opacity: 0.7;
        background: #29a4fe;
        border-radius: 138px;
        box-shadow: 0px 0px 800px 0px rgba(41, 164, 254, 0.71);
        filter: blur(50px);
        transform: translateY(-30px);
        &.active {
          width: 492px;
          height: 382px;
          opacity: 0.8;
          background: #29a4fe;
          border-radius: 138px;
          box-shadow: 0px 0px 800px 0px rgba(41, 164, 254, 0.71);
          filter: blur(50px);
        }
      }
      .bg-img-wrap {
        position: absolute;
        z-index: 2;
        top: 10px;
        top: 2%;
        left: 50%;
        transform: translateX(-50%);
        transition: transform 1s;
        .bg-img-mask {
          position: absolute;
          left: 0;
          top: 0;
          width: 428px;
          height: 328px;
          opacity: 0.05;
          background: #000000;
          border-radius: 32px;
          &.buy-mask {
            opacity: 1;
            background: url('../../assets/home/bg-img-3-mask.png') no-repeat
              center center;
            background-size: 100% 100%;
          }
        }
        &.bg-img-animation {
          transform: translate(-50%, -20px);
          // animation: bgImgAnimation 1s forwards;
        }
        img {
          width: 428px;
          height: 328px;
        }
      }
      .bar-item-wrap {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        display: flex;
        gap: 0 2px;
        top: 0;
        overflow: hidden;
        .bar-item {
          width: 5%;
        }
      }
      .opacity-bg {
        position: absolute;
        z-index: 2;
        top: 15%;
        left: 50%;
        transform: translateX(-50%);
        width: 45%;
        width: 800px;
        height: 515px;
        background: var(--filter-bg);
        filter: blur(50px);
        pointer-events: none;
      }
    }
    .root-content {
      width: 100%;
      position: absolute;
      z-index: 3;
      left: 50%;
      transform: translateX(-50%);
      pointer-events: none;
      min-height: 535px;
      height: calc(100vh - 120px);
      display: flex;
      flex-direction: column;
      align-items: center;
      // justify-content: flex-end;
      top: 30%;
      -webkit-box-align: center;
      .desc {
        font-size: 34px;
        font-family: 'Medium';
        font-weight: 500;
        text-align: center;
        background-image: linear-gradient(180deg, #5b95e8 14%, #1958b3 87%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .desc-2 {
        margin-top: 20px;
        background-image: linear-gradient(180deg, #8bbbff 14%, #2980fe 87%);
        font-size: 62px;
        font-family: 'Black';
        text-align: center;
      }
      .button-list {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        .button-item {
          width: 120px;
          padding: 20px 0 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          // background: var(--bg-3);
          background: var(--bg-6);
          border: 1px solid var(--border-2);
          border-radius: 20px;
          box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
          box-sizing: border-box;
          cursor: pointer;
          pointer-events: auto;
          &::before {
            background: var(--bg-7);
            // box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
            border-radius: 20px;
          }
          &:nth-child(n + 2) {
            margin-left: 16px;
          }
          img {
            width: 44px;
            height: 44px;
          }
          span {
            margin-top: 18px;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            text-align: center;
            color: var(--color-5);
          }
        }
        .button-item:hover {
          // background: var(--bg-7);
          box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
          transition: all 0.3s;
        }
      }
      .start-trading {
        margin: 40px auto 0;
        width: fit-content;
        padding: 0 110px;
        height: 68px;
        line-height: 68px;
        background: #2980fe;
        border: 1px solid #4a94ff;
        border-radius: 28px;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
        font-size: 24px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        pointer-events: auto;
        cursor: pointer;
        &::before {
          background: #216fdf;
          border-radius: 28px;
        }
      }
      .view-more {
        width: fit-content;
        margin: 30px auto 0;
        display: flex;
        align-items: center;
        cursor: pointer;
        pointer-events: auto;
        span {
          font-size: 18px;
          font-family: PingFang SC, PingFang SC-Regular;
          text-align: center;
          color: var(--color-5);
          line-height: 25px;
        }
        img {
          margin-left: 10px;
          width: 30px;
          height: 30px;
        }
      }
      .view-more:hover {
        opacity: 0.8;
      }
    }
  }
  .transition-bg {
    height: 33px;
    background: linear-gradient(180deg, var(--bg) 6%, var(--bg-2));
  }
  .middle-screen {
    background: var(--bg-2);
    padding: 54px 0 78px;
    @list-height: 720px;
    .features-list {
      height: @list-height;
      width: 1108px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      .label-text {
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #ffffff;
      }
      .feature-item {
        position: relative;
        border-radius: 24px;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.4);
        font-size: 0;
        .title {
          padding: 40px 32px 0;
          font-size: 36px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #ffffff;
        }
        .middle-market {
          width: 328px;
          height: 192px;
        }
        .enter {
          position: absolute;
          right: 20px;
          top: 20px;
          width: 24px;
          height: 24px;
        }
      }
      .swap-item {
        width: 328px;
        background: #1a2a3a;
        border: 1px solid #36506b;
        cursor: pointer;
        &::before {
          background: #13202c;
          border-radius: 24px;
        }
        .title {
          display: none;
        }
        .label {
          padding: 40px 32px 0;
          color: #f0f5ff;
        //  color: rgba(240, 245, 255, 0.7);
          height: 140px;
          line-height: 25px;
          img {
            width: 10px;
            height: 10px;
          }
        }
        .enter {
          opacity: 1;
        }
        .middle-market {
          margin-top: 16px;
        }
      }
      .swap-item:hover {
        // background: #13202c;
        .enter {
          right: 12px;
          transition: right 0.2s;
        }
      }
      .buy-token {
        width: 328px;
        background: #2c62ff;
        border: 1px solid #668dff;
        &::before {
          background: #2354e3;
          border-radius: 24px;
        }
        .title {
          display: none;
        }
        .label {
          padding: 40px 32px 33px;
        }
        .middle-market {
          margin-top: 8px;
        }
      }
      .buy-token:hover {
        // background: #2354e3;
        .enter {
          right: 12px;
          transition: right 0.2s;
        }
      }
      .swap-bridge-item {
        width: 458px;
        height: @list-height;
        background: #1ec074;
        border: 1px solid #3df09c;
        box-sizing: border-box;
        font-size: 0;
        margin: 0 20px;
        &::before {
          background: #16a964;
          border-radius: 24px;
        }
        .swap-bridge-img {
          margin-top: 27px;
          width: 100%;
          height: auto;
          object-fit: contain;
        }
        .title {
          // display: none;
        }
        .label {
          padding: 20px 32px 0;
          height: 145px;
        }
      }
      .swap-bridge-item:hover {
        // background: #16a964;
        .enter {
          right: 12px;
          transition: right 0.2s;
        }
      }
      .liquidity-options {
        position: relative;
        padding: 32px 30px;
        width: 282px;
        background: #33a9ff;
        border: 1px solid #73c4ff;
        box-sizing: border-box;
        .title {
          padding: 0;
          display: none;
        }
        .label {
          position: relative;
          z-index: 1;
        }
        .list {
          position: relative;
          z-index: 1;
          margin-top: 24px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .item {
            width: 104px;
            height: 104px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: #269cf1;
            border-radius: 14px;
            &:nth-child(n + 3) {
              margin-top: 12px;
            }
            span {
              font-size: 32px;
              font-family: 'SemiBold';
              font-weight: 700;
              color: #ffffff;
            }
            span:nth-child(2) {
              font-size: 13px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              color: #ffffff;
            }
          }
        }
        .data-bg {
          width: 282px;
          height: 474px;
          position: absolute;
          z-index: 0;
          left: 0;
          top: 0;
        }
      }
      .other-feature {
        position: relative;
        z-index: 10;
        padding: 24px 32px;
        width: 282px;
        // height: 208px;
        background: #786bf7;
        border: 1px solid #a197ff;
        box-sizing: border-box;
        .label-text {
          position: relative;
          z-index: 1;
        }
        .other-feature-list {
          position: relative;
          z-index: 1;
          margin-top: 25px;
          .other-feature-item {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 11px 12px;
            background: #6e61ea;
            border: 1px solid #8478fc;
            border-radius: 8px;
            cursor: pointer;
            &.active {
              background: #5f54cd;
            }
            &:nth-child(n + 2) {
              margin-top: 12px;
            }
            .left {
              display: flex;
              align-items: center;
              img {
                width: 32px;
                height: 32px;
              }
              span {
                margin-left: 12px;
                font-size: 16px;
                font-family: PingFang SC, PingFang SC-Medium;
                font-weight: 500;
                color: #ffffff;
              }
            }
            .unfold {
              width: 12px;
              height: 12px;
            }
            .child-list-bg {
              position: absolute;
              z-index: 50;
              top: 56px;
              left: 0;
              .child-list {
                margin-top: 4px;
                width: 246px;
                padding: 12px;
                box-sizing: border-box;
                background: #6e61ea;
                border: 1px solid #8478fc;
                border-radius: 11px;
                box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.3);
                opacity: 0;
                transform: scaleY(0);
                transform-origin: 50% 0;
                transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s,
                  transform 0.1s cubic-bezier(0.4, 0, 0.2, 1) 0s;
                &.child-list-ani {
                  opacity: 1;
                  transform: scaleY(1);
                }
                .child-item {
                  padding: 10px 8px;
                  // background: #675ae2;
                  border-radius: 12px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  cursor: pointer;
                  &::before {
                    background: #675ae2;
                    border-radius: 12px;
                  }
                  &:nth-child(n + 2) {
                    margin-top: 4px;
                  }
                  .child-left {
                    display: flex;
                    align-items: center;
                    img {
                      width: 24px;
                      height: 24px;
                    }
                    span {
                      margin-left: 8px;
                      font-size: 13px;
                      font-family: PingFang SC, PingFang SC-Medium;
                      font-weight: 500;
                      color: #f0f5ff;
                    }
                  }
                  .down {
                    width: 8px;
                    height: 8px;
                  }
                }
                .child-item:hover {
                  // background: #675ae2;
                }
              }
            }
          }
        }
        .other-bg {
          width: 282px;
          height: 205px;
          position: absolute;
          z-index: 0;
          left: 0;
          top: 0;
        }
      }
    }
    .get-start-bar {
      position: relative;
      width: 1108px;
      box-sizing: border-box;
      margin: 78px auto 0;
      padding: 35px 56px;
      background: #2980fe;
      border: 1px solid #5b9eff;
      border-radius: 24px;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.4);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .bar-left {
        position: relative;
        z-index: 1;
        .bar-title {
          font-size: 30px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #f0f5ff;
          line-height: 42px;
        }
        .bar-desc {
          margin-top: 10px;
          font-size: 20px;
          font-family: PingFang SC, PingFang SC-Regular;
          color: #f0f5ff;
          line-height: 28px;
          img {
            width: 26px;
            height: 26px;
          }
        }
      }
      .start-button {
        position: relative;
        z-index: 1;
        padding: 13px 45px;
        // border: 1px solid #ffffff;
        background: #fff;
        border-radius: 20px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        text-align: center;
        color: #2980fe;
        cursor: pointer;
        font-weight: 500;
        &::before {
          background: #e6f0ff;
          border-radius: 20px;
        }
      }
      .get-start-bg {
        border-radius: 24px;
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}
// 笔记本尺寸
@media screen and (max-width: 1450px) {
   .home-container {
    .first-screen {
      .root-content {
        top: 20%;
      }
    }
  }
}
@media screen and (max-width: 1300px) {
  .home-container {
    .first-screen {
      .background-content {
        .bar-item-wrap {
          display: none;
        }
      }
    }
  }
}
@media screen and(max-width: 1200px) {
  .home-container {
    .first-screen {
      // padding: 0 16px;
      .background-content {
        .opacity-bg {
          width: 500px;
        }
        // top: 45px;
        // .blue-filter {
        //   width: 280px;
        //   height: 263px;
        // }
        // .bg-img-wrap {
        //   width: calc(100% - 32px);
        //   img {
        //     width: 100%;
        //     height: auto;
        //   }
        // }
      }
      // .root-content {
      //   .opacity-bg {
      //     width: 100%;
      //     height: 220px;
      //   }
      //   .desc {
      //     font-size: 22px;
      //     padding: 0 50px;
      //   }
      //   .desc-2 {
      //     margin-top: 16px;
      //     font-size: 31px;
      //   }
      //   .button-list {
      //     width: calc(100% - 32px);
      //     margin: 32px auto 0;
      //     justify-content: space-between;
      //     .button-item {
      //       width: 20%;
      //       padding: 18px 15px 14px;
      //       border-radius: 13px;
      //       &:nth-child(n + 2) {
      //         margin-left: 0;
      //       }
      //       img {
      //         width: 29px;
      //         height: 29px;
      //       }
      //       span {
      //         margin-top: 15px;
      //         font-size: 12px;
      //       }
      //     }
      //   }
      //   .start-trading {
      //     width: calc(100% - 32px);
      //     border-radius: 24px;
      //     padding: 21px 0;
      //     font-size: 16px;
      //     line-height: 22px;
      //   }
      // }
    }
    .middle-screen {
      padding: 20px 46px 78px;
      .features-list {
        height: auto;
        width: 100%;
        flex-direction: row;
        .feature-item {
          margin-top: 36px;
          border-radius: 18px;
          .middle-market {
            width: 100%;
            height: auto;
          }
          .enter {
            right: 15px;
            top: 15px;
            width: 18px;
            height: 18px;
          }
        }
        .swap-item {
          order: 2;
          width: 100%;
          width: 48%;
          margin-top: 36px;
          .title {
            display: block;
            padding: 24px 24px 0;
            font-size: 24px;
          }
          .label {
            padding: 14px 24px 22px;
            font-size: 19px;
          }
        }
        .buy-token {
          order: 3;
          width: 100%;
          width: 48%;
          &::before {
            background: #2c62ff;
          }
          .title {
            display: block;
            padding: 24px 24px 0;
            font-size: 24px;
          }
          .label {
            padding: 14px 24px 22px;
            height: 140px;
            font-size: 19px;
          }
          .middle-market {
            margin-top: 16px;
          }
        }
        .swap-bridge-item {
          order: 1;
          width: 100%;
          height: 494px;
          margin: 0;
          position: relative;
          &::before {
            background: #1ec074;
          }
          .title {
            font-size: 20px;
            padding: 24px 24px 0;
            font-size: 24px;
          }
          .label {
            // width: min(40%, 50%);
            width: 38%;
            font-size: 19px;
            padding: 14px 24px 0;
            // height: 90px;
          }
          .middle-market {
            position: absolute;
            z-index: -1;
            top: 0;
            right: 0;
            width: 492px;
            height: 492px;
            // margin-left: 65%;
            margin-top: 0;
          }
        }
        .liquidity-options {
          order: 4;
          width: 62%;
          padding: 24px;
          padding: 23px 24px;
          .title {
            position: relative;
            z-index: 1;
            display: block;
            font-size: 24px;
            padding: 0;
          }
          .label {
            display: inline-block;
            margin-top: 14px;
            font-size: 19px;
            &:nth-child(3) {
              margin-left: 5px;
            }
          }
          .list {
            margin-top: 23px;
            .item {
              width: 18%;
              &:nth-child(n + 3) {
                margin-top: 0;
              }
              &:nth-child(n + 4) {
                // margin-top: 10px;
                margin-top: 0px;
              }
              &:nth-child(5) {
                // width: 65%;
              }
              span {
                font-size: 24px;
                &:nth-child(2) {
                  margin-top: 5px;
                  font-size: 12px;
                }
              }
            }
          }
          .data-bg {
            border-radius: 18px;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .other-feature {
          order: 5;
          width: 100%;
          width: 34%;
          height: auto;
          padding: 24px;
          .label-text {
            font-size: 24px;
          }
          .other-feature-list {
            margin-top: 30px;
            .other-feature-item {
              border-radius: 12px;
              &:nth-child(n + 2) {
                margin-top: 17px;
              }
              .left {
                img {
                  width: 36px;
                  height: 36px;
                }
              }
              .child-list-bg {
                width: 100%;
                .child-list {
                  width: 100%;
                  border-radius: 18px;
                  .child-item {
                    border-radius: 14px;
                  }
                }
              }
            }
          }
          .other-bg {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .get-start-bar {
        border-radius: 18px;
        width: auto;
        margin: 36px 0 0;
        padding: 24px;
        // flex-direction: column;
        align-items: center;
        .bar-left {
          .bar-title {
            font-size: 24px;
            line-height: 33px;
          }
          .bar-desc {
            margin-top: 14px;
            font-size: 18px;
          }
        }
        .start-button {
          margin-top: 0px;
          border-radius: 12px;
          font-size: 16px;
          white-space: nowrap;
          &::before {
            border-radius: 12px;
            transform: none;
            background: #ffffff;
          }
        }
        .get-start-bg {
          border-radius: 18px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
@media screen and(max-width: 700px) {
  .home-container {
    // .first-screen {
    //   padding: 0 16px;
    //   min-height: calc(100vh - 60px);
    //   .background-content {
    //     top: 45px;
    //     .blue-filter {
    //       width: 280px;
    //       height: 263px;
    //       height: 300px;
    //       transform: translateY(0px);
    //     }
    //     .bg-img-wrap {
    //       width: calc(100% - 32px);
    //       font-size: 0;
    //       .bg-img-mask {
    //         width: 100%;
    //         height: 100%;
    //         border-radius: 50px;
    //         // opacity: 0.2;
    //       }
    //       img {
    //         width: 100%;
    //         height: auto;
    //       }
    //     }
    //     .opacity-bg {
    //       width: 100%;
    //       height: calc(100vh - 260px);
    //       // height: 220px;
    //       opacity: 0.96;
    //       background: linear-gradient(180deg,rgba(244,249,255,0.00), var(--filter-bg) 18%);
    //       filter: blur(5px);
    //     }
    //   }
    //   .root-content {
    //     // top: 55%;
    //     .desc {
    //       font-size: 34px;
    //       padding: 0 20px;
    //     }
    //     .desc-2 {
    //       padding: 0 16px;
    //       margin-top: 16px;
    //       font-size: 62px;
    //     }
    //     .button-list {
    //       width: calc(100% - 32px);
    //       margin: 32px auto 0;
    //       justify-content: space-between;
    //       .button-item {
    //         width: 22%;
    //         padding: 18px 15px 14px;
    //         border-radius: 13px;
    //         &:nth-child(n + 2) {
    //           margin-left: 0;
    //         }
    //         img {
    //           width: 29px;
    //           height: 29px;
    //         }
    //         span {
    //           margin-top: 15px;
    //           font-size: 12px;
    //         }
    //         &::before {
    //           background: none;
    //         }
    //       }
    //       .button-item:hover {
    //         background: var(--bg-6);
    //         box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
    //       }
    //     }
    //     .start-trading {
    //       width: calc(100% - 32px);
    //       border-radius: 24px;
    //       padding: 21px 0;
    //       font-size: 16px;
    //       line-height: 22px;
    //       &::before {
    //         background: #2980fe;
    //       }
    //     }
    //     .view-more:hover {
    //       opacity: 1;
    //     }
    //   }
    // }
    .middle-screen {
      padding: 40px 16px 78px;
      .features-list {
        height: auto;
        width: 100%;
        .feature-item {
          margin-top: 22px;
          border-radius: 18px;
          .middle-market {
            width: 100%;
            height: auto;
          }
          .enter {
            right: 15px;
            top: 15px;
            width: 18px;
            height: 18px;
          }
          .label {
            // font-size: 16px;
          }
        }
        .swap-item {
          order: 2;
          width: 100%;
          margin-top: 22px;
          .title {
            display: block;
            padding: 24px 24px 0;
            font-size: 30px;
          }
          .label {
            height: 120px;
            padding: 14px 24px 0;
            font-size: 22px;
          }
        }
        .buy-token {
          order: 3;
          width: 100%;
          .title {
            display: block;
            padding: 24px 24px 0;
            font-size: 30px;
          }
          .label {
            padding: 14px 24px 22px;
            font-size: 22px;
          }
        }
        .buy-token:hover {
          .enter {
            right: 15px;
            transition: none;
          }
        }
        .swap-bridge-item {
          order: 1;
          width: 100%;
          height: auto;
          margin-top: 0;
          .title {
            font-size: 30px;
            padding: 24px 24px 0;
          }
          .label {
            padding: 14px 24px 0;
            height: 140px;
            width: 100%;
            font-size: 22px;
          }
          .middle-market {
            position: static;
          }
        }
        .swap-bridge-item:hover {
          .enter {
            right: 15px;
            transition: none;
          }
        }
        .liquidity-options {
          order: 4;
          width: 100%;
          padding: 24px;
          .title {
            position: relative;
            z-index: 1;
            display: block;
            font-size: 30px;
            padding: 0;
          }
          .label {
            display: inline-block;
            margin-top: 14px;
            font-size: 22px;
            &:nth-child(3) {
              margin-left: 5px;
            }
          }
          .list {
            .item {
              width: 30%;
              &:nth-child(n + 3) {
                margin-top: 0;
              }
              &:nth-child(n + 4) {
                margin-top: 10px;
              }
              &:nth-child(5) {
                width: 65%;
              }
              span {
                font-size: 24px;
                &:nth-child(2) {
                  margin-top: 5px;
                  font-size: 12px;
                }
              }
            }
          }
          .data-bg {
            border-radius: 18px;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .other-feature {
          order: 5;
          width: 100%;
          height: auto;
          padding: 24px;
          .label-text {
            font-size: 30px;
          }
          .other-feature-list {
            .other-feature-item {
              border-radius: 14px;
              .left {
                img {
                  width: 36px;
                  height: 36px;
                }
              }
              .child-list-bg {
                top: 65px;
                width: 100%;
                .child-list {
                  width: 100%;
                  border-radius: 18px;
                  .child-item {
                    border-radius: 14px;
                    .child-left {
                      span {
                        font-size: 15px;
                      }
                    }
                    .down {
                      width: 9px;
                      height: 9px;
                    }
                  }
                }
              }
            }
          }
          .other-bg {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .get-start-bar {
        border-radius: 18px;
        width: auto;
        margin: 24px 0 0;
        padding: 24px;
        // flex-direction: column;
        align-items: flex-start;
        .bar-left {
          .bar-title {
            font-size: 24px;
            line-height: 33px;
          }
          .bar-desc {
            margin-top: 14px;
            font-size: 16px;
          }
        }
        .start-button {
          margin-top: 22px;
          border-radius: 14px;
          font-size: 16px;
        }
        .get-start-bg {
          border-radius: 18px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

@media screen and(max-width: 680px) {
  .home-container {
    .first-screen {
      padding: 0 16px;
      min-height: calc(100vh - 60px);
      .background-content {
        top: 0px;
        .blue-filter {
          width: 280px;
          height: 263px;
          height: 300px;
          transform: translateY(0px);
        }
        .bg-img-wrap {
          width: calc(100% - 32px);
          font-size: 0;
          .bg-img-mask {
            width: 100%;
            height: 100%;
            border-radius: 50px;
          }
          img {
            width: 100%;
            height: auto;
          }
        }
        .opacity-bg {
          width: 100%;
          height: calc(100vh - 260px);
          opacity: 0.96;
          background: linear-gradient(
            180deg,
            rgba(244, 249, 255, 0),
            var(--filter-bg) 18%
          );
          filter: blur(5px);
        }
        .bg-img-wrap {
          .bg-img-mask {
            border-radius: 24px;
          }
        }
      }
      .root-content {
        top: 30%;
        .desc {
          font-size: 24px;
          padding: 0 16px;
          line-height: 40px;
        }
        .desc-2 {
          margin-top: 16px;
          font-size: 36px;
        }
        .button-list {
          width: calc(100% - 32px);
          margin: 28px auto 0;
          justify-content: space-between;
          .button-item {
            width: 22%;
            padding: 18px 15px 14px;
            border-radius: 13px;
            &:nth-child(n + 2) {
              margin-left: 0;
            }
            img {
              width: 29px;
              height: 29px;
            }
            span {
              margin-top: 15px;
              font-size: 12px;
            }
            &::before {
              background: none;
            }
          }
          .button-item:hover {
            background: var(--bg-6);
            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
          }
        }
        .start-trading {
          width: calc(100% - 32px);
          height: 58px;
          line-height: 58px;
          border-radius: 24px;
          padding: 0;
          font-size: 18px;
          margin: 30px auto 0;
          &::before {
            background: #2980fe;
          }
        }
        .view-more {
          span {
            font-size: 16px;
          }
        }
        .view-more:hover {
          opacity: 1;
        }
      }
    }
    .middle-screen {
      .features-list {
        .feature-item {
          .middle-market {
            width: 100%;
            height: auto;
          }
          .enter {
            right: 15px;
            top: 15px;
            width: 18px;
            height: 18px;
          }
          .label {
            // font-size: 16px;
          }
        }
        .swap-item {
          order: 2;
          width: 100%;
          margin-top: 22px;
          .title {
            display: block;
            padding: 24px 24px 0;
            font-size: 22px;
          }
          .label {
            height: 120px;
            padding: 14px 24px 0;
            font-size: 18px;
          }
        }
        .buy-token {
          order: 3;
          width: 100%;
          .title {
            display: block;
            padding: 24px 24px 0;
            font-size: 22px;
          }
          .label {
            padding: 14px 24px 22px;
            font-size: 18px;
          }
        }
        .buy-token:hover {
          .enter {
            right: 15px;
            transition: none;
          }
        }
        .swap-bridge-item {
          order: 1;
          width: 100%;
          height: auto;
          margin-top: 0;
          .title {
            font-size: 22px;
            padding: 24px 24px 0;
          }
          .label {
            padding: 14px 24px 0;
            height: 190px;
            font-size: 18px;
            width: 100%;
          }
          .middle-market {
            position: static;
          }
        }
        .swap-bridge-item:hover {
          .enter {
            right: 15px;
            transition: none;
          }
        }
        .liquidity-options {
          order: 4;
          width: 100%;
          padding: 24px;
          .title {
            position: relative;
            z-index: 1;
            display: block;
            font-size: 22px;
            padding: 0;
          }
          .label {
            display: inline-block;
            margin-top: 14px;
            font-size: 18px;
            &:nth-child(3) {
              margin-left: 5px;
            }
          }
          .list {
            .item {
              width: 30%;
              &:nth-child(n + 3) {
                margin-top: 0;
              }
              &:nth-child(n + 4) {
                margin-top: 10px;
              }
              &:nth-child(5) {
                width: 65%;
              }
              span {
                font-size: 24px;
                &:nth-child(2) {
                  margin-top: 5px;
                  font-size: 12px;
                }
              }
            }
          }
          .data-bg {
            border-radius: 18px;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .other-feature {
          order: 5;
          width: 100%;
          height: auto;
          padding: 24px;
          .label-text {
            font-size: 20px;
          }
          .other-feature-list {
            .other-feature-item {
              border-radius: 14px;
              .left {
                img {
                  width: 36px;
                  height: 36px;
                }
              }
              .child-list-bg {
                top: 65px;
                width: 100%;
                .child-list {
                  width: 100%;
                  border-radius: 18px;
                  .child-item {
                    border-radius: 14px;
                    .child-left {
                      span {
                        font-size: 15px;
                      }
                    }
                  }
                }
              }
            }
          }
          .other-bg {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .get-start-bar {
        border-radius: 18px;
        width: auto;
        margin: 24px 0 0;
        padding: 24px;
        flex-direction: column;
        align-items: flex-start;
        .bar-left {
          .bar-title {
            font-size: 24px;
            line-height: 33px;
          }
          .bar-desc {
            margin-top: 14px;
            font-size: 16px;
          }
        }
        .start-button {
          margin-top: 22px;
          border-radius: 14px;
          font-size: 16px;
        }
        .get-start-bg {
          border-radius: 18px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

@media screen and(max-width: 280px) {
  .home-container {
    .first-screen {
      .background-content {
        .opacity-bg {
          height: calc(100vh - 300px);
          top: 3%;
        }
      }
    }
    .middle-screen {
      .features-list {
        .swap-bridge-item {
          .label {
            height: 270px;
          }
        }
      }
    }
  }
}
</style>

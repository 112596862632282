<template>
  <div class="fixed-container" v-if="isShow">
    <div class="outer">
      <div class="app-toast"
        :class="{
         'info': type=== 'info',
         'success': type=== 'success',
         'warning': type=== 'warning',
         'danger': type=== 'danger',
         'pc': this.isPC,
         'sentence': this.isSentence
      }">{{text}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "toast",
  data() {
    return {
      isShow: false,
      isPC: false,
      isSentence: false
    }
  }
}
</script>

<style scoped lang="less">
.fixed-container {
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  .outer {
    transform: translate(100%,100%);
    position: absolute;
    width: 100%;
    height: 100%;
    .app-toast {
      position: absolute;
      transform: translate(-50%,-50%);
      top: -50%;
      left: -50%;
      width: auto;
      max-width: 100%;
      font-size: 0.24rem;
      color: white;
      background: rgba(0,0,0,0.8);
      border-radius: 0.05rem;
      padding: 0.15rem 0.3rem;

      &.pc {
        font-size: 0.16rem;
      }
    }
  }
}
.info {
  background: #00aaee;
}
.success {
  background: #00ee6b;
}
.warning {
  background: #eea300;
}
.danger {
  background: #ee000c;
}
</style>

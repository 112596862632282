import dayjs from 'dayjs';
export default {
  methods: {
    parsePrice(val) {
      val = parseFloat(val)
      try {
        if (String(val).indexOf('e') !== -1) {
          const str = String(val);
          const index = str.indexOf('e');
          if (str.charAt(index + 1) !== '-') {
            return val;
          }
          const zeroCount = Number(str.substring(index + 2)) - 1;
          const efficientNumbers = str
            .substring(0, index)
            .split('.')
            .join('')
            .substring(0, 3);
          return `0.0{${zeroCount}}${efficientNumbers}`;
        } else if (val >= 10) {
          return val.toFixed(2);
        } else if (val < 10 && val >= 1) {
          return val.toFixed(3);
        } else if (val > 0) {
          const arr = val.toString().split('');
          const pointIndex = arr.indexOf('.');
          if (pointIndex >= 0) {
            let firstEfficientIndex = pointIndex;
            for (let i = pointIndex + 1; i < arr.length; i++) {
              if (arr[i] !== '0') {
                firstEfficientIndex = i;
                break;
              }
            }
            if (val < 1 && val >= 0.0001) {
              return arr
                .slice(0, Math.min(firstEfficientIndex + 3, arr.length))
                .join('');
            } else {
              return `0.0{${firstEfficientIndex - pointIndex - 1}}${arr
                .slice(
                  firstEfficientIndex,
                  Math.min(firstEfficientIndex + 3, arr.length)
                )
                .join('')}`;
            }
          }
        } else {
          return val;
        }
      } catch (err) {
        console.log(err, val);
      }
    },
    parseWithUnit(val, type) {
      try {
        if (val >= 1000000000000) {
          return Math.round(val / 1000000000) / 1000 + 'T';
        } else if (val < 1000000000000 && val >= 1000000000) {
          return Math.round(val / 1000000) / 1000 + 'B';
        } else if (val < 1000000000 && val >= 1000000) {
          return Math.round(val / 10000) / 100 + 'M';
        } else if (val < 1000000 && val >= 10000) {
          return Math.round(val / 10) / 100 + 'K';
        } else if (val < 10000 && val >= 1) {
          return val.toFixed(1);
        } else if (val > 0) {
          if (type === 'tvl') {
            return 0;
          } else {
            const arr = val.toString().split('');
            const pointIndex = arr.indexOf('.');
            if (pointIndex >= 0) {
              let firstEfficientIndex = pointIndex;
              for (let i = pointIndex + 1; i < arr.length; i++) {
                if (arr[i] !== '0') {
                  firstEfficientIndex = i;
                  break;
                }
              }
              return arr
                .slice(0, Math.min(firstEfficientIndex + 3, arr.length))
                .join('');
            }
          }
        } else {
          return 0;
        }
      } catch (err) {
        console.log(err, val);
      }
    },
    getPriceScale(val) {
      let priceScale;
      if (val >= 10) {
        priceScale = 100;
      } else if (val < 10 && val >= 1) {
        priceScale = 1000;
      } else if (val < 1 && val >= 0.0001) {
        priceScale = 100;
        while (val < 1) {
          val *= 10;
          priceScale *= 10;
        }
      } else if (val < 0.0001 && val > 0) {
        priceScale = 1000;
        while (val < 1) {
          val *= 10;
          priceScale *= 10;
        }
      } else {
        priceScale = 1000;
      }
      return priceScale;
    },
    formatTime(timeStr) {
      const time = timeStr * 1000;
      const now = new Date().getTime();
      if (now - time < 60 * 1000) {
        const seconds = Math.floor((now - time) / 1000);
        return this.$t('timeAgo', { time: this.$t('second', { seconds }) });
      } else if (now - time < 60 * 60 * 1000) {
        const minutes = Math.floor((now - time) / (60 * 1000));
        return this.$t('timeAgo', { time: this.$t('minute', { minutes }) });
      } else if (now - time < 24 * 60 * 60 * 1000) {
        const hours = Math.floor((now - time) / (60 * 60 * 1000));
        return this.$t('timeAgo', { time: this.$t('hour', { hours }) });
      } else if (now - time < 365 * 24 * 60 * 60 * 1000) {
        return dayjs(time).format('MM-DD HH:mm');
      } else {
        return dayjs(time).format('YYYY-MM-DD HH:mm');
      }
    },
    omit(str, start, end) {
      if (str.length <= start + end) {
        return str;
      }
      return `${str.substring(0, start)}...${str.substring(str.length - end)}`;
    },
    changeURLArg(obj) {
      const query = location.search.substr(1);
      let queryObj = {};
      if (query) {
        _.forEach(query.split('&'), (item) => {
          let tempArr = item.split('=');
          queryObj[tempArr[0]] = tempArr[1];
        });
      }
      queryObj = { ...queryObj, ...obj };

      const newQuery = Object.keys(queryObj)
        .filter((key) => queryObj[key])
        .map((key) => `${key}=${queryObj[key]}`)
        .join('&');

      history.replaceState('', '', `${location.pathname}?${newQuery}#/swap`);
    },
  },
};

<template>
  <div class="toast-copy">
    <div class="toast-copy-content">
      <span>{{ $t('copied') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit('closeToast');
    },
  },
};
</script>

<style lang="less" scoped>
.toast-copy {
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 60px;
  background: var(--toast-bg);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .toast-copy-content {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      font-size: 16px;
      color: var(--toast-color);
    }
  }
}
@media screen and (max-width: 767px) {
  .toast-copy {
    width: 176px;
    height: 48px;
    .toast-copy-content {
      span {
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        font-size: 13px;
        color: var(--toast-color);
      }
    }
  }
}
</style>

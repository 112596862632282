import Vue from 'vue'
import VModal from 'vue-js-modal'
import App from './App.vue'
import './style/index.less'
import router from './router'
import store from "./store";
import i18n from './i18n/index'
import mitt from 'mitt';
import tp from "tp-js-sdk";
import "./assets/fonts/selfFont.css";

import toastRegistry from './tools/toast'
import clipboard from './directive/clipboard';
import { setMode, createQueryObj, changeURLArg } from "./utils";
const $mitt = mitt();
Vue.prototype.$mitt = $mitt;
Vue.use(VModal)
Vue.use(clipboard)
// import Vconsole from 'vconsole'
// let vConsole = new Vconsole();
// Vue.use(vConsole)
Vue.use(toastRegistry)

Vue.config.productionTip = false
// 跳转顶部
router.afterEach(() => {
  window.scrollTo(0, 0);
});

let queryObj = createQueryObj();

// 允许的三种模式，如果都不符合则按照系统的模式
const availableTheme = ['light', 'dark', 'auto'];
let theme = ''
let queryTheme = _.includes(availableTheme, queryObj['theme']) ? queryObj['theme'] : 'auto';
if (queryObj['theme']) {
  theme = queryTheme
} else {
  theme = localStorage.getItem('theme');
}
changeURLArg({ theme })
let mode = false;

// 如果已经设置过暗黑模式
const isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)'); // 是深色
// console.log('isDarkTheme', isDarkTheme);
if (theme) {
  if (theme === 'auto' && isDarkTheme) {
    if (isDarkTheme.matches) {
      // 主题设置为深色。
      mode = true;
    } else {
      // 主题设置为浅色。
      mode = false;
    }
  } else if (theme == 'dark') {
    mode = true;
  } else {
    mode = false;
  }
  setMode(mode)
  store.commit('setDarkMode', mode)
} else {
  if (navigator.userAgent.indexOf('TokenPocket') === -1) {
    if (isDarkTheme.matches) {
      // 主题设置为深色。
      mode = true;
    } else {
      // 主题设置为浅色。
      mode = false;
    }
    setMode(mode)
    store.commit('setDarkMode', mode)
  } else {
    tp.isDarkMode().then(res => {
      // console.log('tp', res);
      if (res && res.data) {
        mode = true
      } else {
        mode = false
      }
      setMode(mode)
      store.commit('setDarkMode', mode)
    })
  }
}

// router.beforeEach((to, from, next) => {
//   /* 路由发生变化修改页面title */
//   document.title = to.meta.title
//   next()
// })

// Vue.mixin({
//   computed: {
//     lang() {
//       return location.href.indexOf('/en') === -1 ? 'zh' : 'en'
//     },
//   },
//   methods: {
//     toUrl(type, url) {
//       if (type === 'to') {
//         window.location = url
//       } else if (type === 'open') {
//         window.open(url)
//       }
//     },
//     switchLang() {
//       window.location = location.href.indexOf('/en') === -1
//         ? location.href.replace('/zh', '/en')
//         : location.href.replace('/en', '/zh')
//     },
//   }
// })
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('custom-render-trigger'))
  }
}).$mount('#app')
